export const TIMEZONE_ALIASES = {
    'Asia/Calcutta': 'Asia/Kolkata',
    'America/Atka': 'America/Adak',
    'America/Coral_Harbour': 'America/Atikokan',
    'America/Ensenada': 'America/Tijuana',
    'America/Fort_Wayne': 'America/Indiana/Indianapolis',
    'America/Indianapolis': 'America/Indiana/Indianapolis',
    'America/Knox_IN': 'America/Indiana/Knox',
    'America/Louisville': 'America/Kentucky/Louisville',
    'America/Porto_Acre': 'America/Rio_Branco',
    'America/Rosario': 'America/Argentina/Cordoba',
    'America/Santa_Isabel': 'America/Tijuana',
    'Antarctica/South_Pole': 'Antarctica/McMurdo',
    'Asia/Ashkhabad': 'Asia/Ashgabat',
    'Asia/Chongqing': 'Asia/Shanghai',
    'Asia/Chungking': 'Asia/Shanghai',
    'Asia/Dacca': 'Asia/Dhaka',
    'Asia/Harbin': 'Asia/Shanghai',
    'Asia/Istanbul': 'Europe/Istanbul',
    'Asia/Katmandu': 'Asia/Kathmandu',
    'Asia/Macao': 'Asia/Macau',
    'Asia/Rangoon': 'Asia/Yangon',
    'Asia/Saigon': 'Asia/Ho_Chi_Minh',
    'Asia/Tel_Aviv': 'Asia/Jerusalem',
    'Asia/Thimbu': 'Asia/Thimphu',
    'Asia/Ujung_Pandang': 'Asia/Makassar',
    'Asia/Ulan_Bator': 'Asia/Ulaanbaatar',
    'Atlantic/Faeroe': 'Atlantic/Faroe',
    'Atlantic/Jan_Mayen': 'Europe/Oslo',
    'Australia/ACT': 'Australia/Sydney',
    'Australia/Canberra': 'Australia/Sydney',
    'Australia/LHI': 'Australia/Lord_Howe',
    'Australia/North': 'Australia/Darwin',
    'Australia/NSW': 'Australia/Sydney',
    'Australia/Queensland': 'Australia/Brisbane',
    'Australia/South': 'Australia/Adelaide',
    'Australia/Tasmania': 'Australia/Hobart',
    'Australia/Victoria': 'Australia/Melbourne',
    'Australia/West': 'Australia/Perth',
    'Australia/Yancowinna': 'Australia/Broken_Hill',
    'Brazil/Acre': 'America/Rio_Branco',
    'Brazil/DeNoronha': 'America/Noronha',
    'Brazil/East': 'America/Sao_Paulo',
    'Brazil/West': 'America/Manaus',
    'Canada/Atlantic': 'America/Halifax',
    'Canada/Central': 'America/Winnipeg',
    'Canada/Eastern': 'America/Toronto',
    'Canada/Mountain': 'America/Edmonton',
    'Canada/Newfoundland': 'America/St_Johns',
    'Canada/Pacific': 'America/Vancouver',
    'Canada/Saskatchewan': 'America/Regina',
    'Canada/Yukon': 'America/Whitehorse',
    'Chile/Continental': 'America/Santiago',
    'Chile/EasterIsland': 'Pacific/Easter',
    'CST6CDT': 'America/Chicago',
    'EET': 'Europe/Helsinki',
    'Eire': 'Europe/Dublin',
    'EST': 'America/Jamaica',
    'EST5EDT': 'America/New_York',
    'Etc/GMT+0': 'Etc/GMT',
    'Etc/GMT+1': 'Etc/GMT-1',
    'Etc/GMT+10': 'Etc/GMT-10',
    'Etc/GMT+11': 'Etc/GMT-11',
    'Etc/GMT+12': 'Etc/GMT-12',
    'Etc/GMT+2': 'Etc/GMT-2',
    'Etc/GMT+3': 'Etc/GMT-3',
    'Etc/GMT+4': 'Etc/GMT-4',
    'Etc/GMT+5': 'Etc/GMT-5',
    'Etc/GMT+6': 'Etc/GMT-6',
    'Etc/GMT+7': 'Etc/GMT-7',
    'Etc/GMT+8': 'Etc/GMT-8',
    'Etc/GMT+9': 'Etc/GMT-9',
    'Etc/GMT-0': 'Etc/GMT',
    'Etc/GMT-1': 'Etc/GMT+1',
    'Etc/GMT-10': 'Etc/GMT+10',
    'Etc/GMT-11': 'Etc/GMT+11',
    'Etc/GMT-12': 'Etc/GMT+12',
    'Etc/GMT-13': 'Etc/GMT+13',
    'Etc/GMT-14': 'Etc/GMT+14',
    'Etc/GMT-2': 'Etc/GMT+2',
    'Etc/GMT-3': 'Etc/GMT+3',
    'Etc/GMT-4': 'Etc/GMT+4',
    'Etc/GMT-5': 'Etc/GMT+5',
    'Etc/GMT-6': 'Etc/GMT+6',
    'Etc/GMT-7': 'Etc/GMT+7',
    'Etc/GMT-8': 'Etc/GMT+8',
    'Etc/GMT-9': 'Etc/GMT+9',
    'Etc/Greenwich': 'Etc/GMT',
    'Etc/UCT': 'Etc/UTC',
    'Etc/Universal': 'Etc/UTC',
    'Etc/Zulu': 'Etc/UTC',
    'Europe/Belfast': 'Europe/London',
    'Europe/Nicosia': 'Asia/Nicosia',
    'Europe/Tiraspol': 'Europe/Chisinau',
    'GB': 'Europe/London',
    'GB-Eire': 'Europe/London',
    'GMT': 'Etc/GMT',
    'GMT+0': 'Etc/GMT',
    'GMT-0': 'Etc/GMT',
    'GMT0': 'Etc/GMT',
    'Greenwich': 'Etc/GMT',
    'Hongkong': 'Asia/Hong_Kong',
    'Iceland': 'Atlantic/Reykjavik',
    'Iran': 'Asia/Tehran',
    'Israel': 'Asia/Jerusalem',
    'Japan': 'Asia/Tokyo',
    'Kwajalein': 'Pacific/Kwajalein',
    'Libya': 'Africa/Tripoli',
    'Mexico/BajaNorte': 'America/Tijuana',
    'Mexico/BajaSur': 'America/Mazatlan',
    'Mexico/General': 'America/Mexico_City',
    'NZ': 'Pacific/Auckland',
    'NZ-CHAT': 'Pacific/Chatham',
    'Navajo': 'America/Denver',
    'PRC': 'Asia/Shanghai',
    'Pacific/Johnston': 'Pacific/Honolulu',
    'Pacific/Ponape': 'Pacific/Pohnpei',
    'Pacific/Samoa': 'Pacific/Pago_Pago',
    'Pacific/Truk': 'Pacific/Chuuk',
    'Pacific/Yap': 'Pacific/Chuuk',
    'Poland': 'Europe/Warsaw',
    'Portugal': 'Europe/Lisbon',
    'ROC': 'Asia/Taipei',
    'ROK': 'Asia/Seoul',
    'Singapore': 'Asia/Singapore',
    'Turkey': 'Europe/Istanbul',
    'UCT': 'Etc/UTC',
    'US/Alaska': 'America/Anchorage',
    'US/Aleutian': 'America/Adak',
    'US/Arizona': 'America/Phoenix',
    'US/Central': 'America/Chicago',
    'US/East-Indiana': 'America/Indiana/Indianapolis',
    'US/Eastern': 'America/New_York',
    'US/Hawaii': 'Pacific/Honolulu',
    'US/Indiana-Starke': 'America/Indiana/Knox',
    'US/Michigan': 'America/Detroit',
    'US/Mountain': 'America/Denver',
    'US/Pacific': 'America/Los_Angeles',
    'US/Samoa': 'Pacific/Pago_Pago',
    'W-SU': 'Europe/Moscow',
};