import React from "react";
import { Icon } from "@iconify/react";
import styles from "../../Pages/DroppingOdds/DroppingOdds.module.css";

const AboutDroppingOdds = (props) => {
  return (
    <div className={styles.aboutDroppingOdds}>
      <label> {props.title} {props.sureBets && (<span> {" "} - <span>{props.sureBets}</span> </span>)} </label>

      <p className={!props.disciption && styles.aboutdiscription}>
        The following odds experienced a big drop recently. Yellow box shows
        average previous odds along with average current odds (in bold). Use
        filter to select time period, betting type and the minimum percentage of
        bookmakers with dropping odds.
        {props.sureBets &&
          (<div className="mt-3">
            <b> Want more than 10 sure bets? <u>GET MORE</u>{" "} </b>
          </div>
          )}
      </p>
    </div>
  );
};

export default AboutDroppingOdds;
