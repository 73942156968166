const BOOKIES_DATA = {
  1: {
    idProvider: "1",
    logo: "1.png",
    WebName: "Interwetten",
    WebUrl: "interwetten",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatesys.interwetten.com/redirect.aspx?pid=3651&bid=5819",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 236,
    isNew: false,
    bonus: {
      id: "272",
      title: "100% Welcome Bonus up to €100!",
      text: "Register a new account and receive 100% first deposit bonus up to €100!",
    },
  },
  2: {
    idProvider: "2",
    logo: "2.png",
    WebName: "bwin",
    WebUrl: "bwin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://mediaserver.bwinpartypartners.com/renderBanner.do?zoneId=1793122",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 148,
    isNew: false,
  },
  3: {
    idProvider: "3",
    logo: "3.png",
    WebName: "bet-at-home",
    WebUrl: "bet-at-home",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://wlbetathome.adsrv.eacdn.com/wl/clk/?btag=a_74417b_31114&aid=",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 30,
    isNew: false,
    bonus: {
      id: "6",
      title: "50% Bonus up to €50!",
      text: "Register now and receive a 50% bonus up to €50! Type in the bonus code “FIRST“.",
    },
  },
  4: {
    idProvider: "4",
    WebName: "Centrebet",
    WebUrl: "centrebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.centrebet.com/processing/clickthrgh.asp?btag=a_3543b_38",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 161,
    isNew: false,
  },
  5: {
    idProvider: "5",
    logo: "5.png",
    WebName: "Unibet",
    WebUrl: "unibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://dspk.kindredplc.com/redirect.aspx?pid=37495&bid=17869",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 409,
    isNew: false,
    bonus: {
      id: "41",
      title: "€25 Welcome Offer!",
      text: "Sign up with Unibet. Bet on any of pre match or live odds. If your bet loses you will get your money back, up to €25!",
    },
  },
  7: {
    idProvider: "7",
    WebName: "Betsson exch.",
    WebUrl: "betsson-exch",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "http://record.affiliatelounge.com/_PQI2ELGFWbxEvUwbScsNtHo87MuRJ3qO/1",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 126,
    isNew: false,
  },
  8: {
    idProvider: "8",
    logo: "8.png",
    WebName: "Stan James",
    WebUrl: "stan-james",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.stanjamesaffiliates.com/processing/clickthrgh.asp?btag=a_15077b_1537",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 360,
    isNew: false,
    bonus: {
      id: "39",
      title: "Bet £10, Get £10!",
      text: "Register with Stan James and they\\'ll give you a £10 free bet!",
    },
  },
  9: {
    idProvider: "9",
    logo: "9.png",
    WebName: "Expekt",
    WebUrl: "expekt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.beaffiliates.com/processing/clickthrgh.asp?btag=a_1221b_1945",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 193,
    isNew: false,
    bonus: {
      id: "22",
      title: "Bet £30 Get £10!",
      text: "Make your first bet and get free bets up to £10!",
    },
  },
  10: {
    idProvider: "10",
    WebName: "PartyBets",
    WebUrl: "partybets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.partybets.com/?wm=3208828",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 309,
    isNew: false,
  },
  11: {
    idProvider: "11",
    WebName: "Gamebookers",
    WebUrl: "gamebookers",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.gamebookers.com/cgi-bin/intro.cgi?promotion=gb_30eu_en_lp&l=en&wm=3215213",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 208,
    isNew: false,
  },
  12: {
    idProvider: "12",
    WebName: "Canbet",
    WebUrl: "canbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.commissionking.com/_2f9z6wUDJ5utC2rdio9_kmNd7ZgqdRLk/1",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 158,
    isNew: false,
  },
  14: {
    idProvider: "14",
    logo: "14.png",
    WebName: "10Bet",
    WebUrl: "10bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partners.10bet.com/processing/clickthrgh.asp?btag=a_15596b_313",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 1,
    isNew: false,
    bonus: {
      id: "3",
      title: "Bonus info!",
      text: "Open 10bet account Today to check for offers and details!",
    },
  },
  15: {
    idProvider: "15",
    logo: "15.png",
    WebName: "William Hill",
    WebUrl: "william-hill",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads2.williamhill.com/redirect.aspx?pid=39394982&lpid=1487414232&bid=1470162595",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 435,
    isNew: false,
    bonus: {
      id: "221",
      title: "Bet £10 Get £30 in free bets",
      text: "New William Hill online customers only. Min. unit stake of £10/€10 at odds of 1.5 or greater. Only the “win” part from EW bets will count. Free bet terms, payment method & country restrictions apply. Free bets credited as 3 x £10/€10. Free bet stakes are not returned as part of the settlement of successful free bets. All Free Bets must be wagered within 3days.",
    },
  },
  16: {
    idProvider: "16",
    logo: "16.png",
    WebName: "bet365",
    WebUrl: "bet365",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.allsport365.com/olp/open-account?affiliate=365_193220",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "y",
    sortKey: 36,
    isNew: false,
    bonus: {
      id: "8",
      title: "BONUS INFO",
      text: "Open bet365.com account today to check for offers and details!",
    },
  },
  17: {
    idProvider: "17",
    WebName: "Blue Square",
    WebUrl: "blue-square",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.bluesq.com/redirect.aspx?pid=4776&bid=1295",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 143,
    isNew: false,
    bonus: {
      id: "16",
      title: "Get £25 free bet",
      text: "Get up to £25 free bet when you join Blue Square today! UK and Ireland residents only.",
    },
  },
  18: {
    idProvider: "18",
    logo: "18.png",
    WebName: "Pinnacle",
    WebUrl: "pinnacle",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.pinnaclesports.com/processing/clickthrgh.asp?btag=a_3873b_1",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 313,
    isNew: false,
  },
  19: {
    idProvider: "19",
    logo: "19.png",
    WebName: "Sports Interaction",
    WebUrl: "sports-interaction",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.sportsinteraction.com/processing/clickthrgh.asp?btag=a_4317b_102",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 354,
    isNew: false,
  },
  20: {
    idProvider: "20",
    logo: "20.png",
    WebName: "5Dimes",
    WebUrl: "5dimes",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.5dimes.com/tracking/Affiliate.asp?AffID=AF000608&mediaTypeID=220&AffUrlID=684",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 14,
    isNew: false,
    bonus: {
      id: "4",
      title: "50% Reward on New Accounts!",
      text: "Earn up to $520 in Free-Play Cash to use in Sportsbook!",
    },
  },
  21: {
    idProvider: "21",
    logo: "21.png",
    WebName: "Betfred",
    WebUrl: "betfred",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://activewin.adsrv.eacdn.com/C.ashx?btag=a_6796b_13047c_&affid=6884&siteid=6796&adid=13047&c=",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 90,
    isNew: false,
    bonus: {
      id: "10",
      title: "Get £60 in Bonuses When You Stake £10!",
      text: "New UK customers only. Register using the promo code BETFRED60, deposit andplace first bet of £10+ on Sports (cumulative Evens+) within 7 days of registration. First bet must be on Sports. £20 in Free Bets to be used on Sports, £10 in Free Bets to be used on Lotto and 50 Free Spins (20p per spin) credited within 48 hours of bet settlement. Further £20 in Free Bets credited 5 days after settlement. Bonuses have a 7-day expiry. Payment restrictions apply. Full T&Cs apply.",
    },
  },
  23: {
    idProvider: "23",
    logo: "23.png",
    WebName: "DOXXbet",
    WebUrl: "doxxbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.doxxbet.com/redirect.aspx?pid=2135&bid=1473",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 180,
    isNew: false,
    bonus: {
      id: "129",
      title: "100% first deposit bonus up to €2 000!",
      text: "Register a new account and receive 100% first deposit bonus up to €2 000!",
    },
  },
  24: {
    idProvider: "24",
    logo: "24.png",
    WebName: "Betsafe",
    WebUrl: "betsafe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.affiliatelounge.com/_PQI2ELGFWbzk8xEDyeOw_GNd7ZgqdRLk/7",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 119,
    isNew: false,
  },
  26: {
    idProvider: "26",
    logo: "26.png",
    WebName: "Betway",
    WebUrl: "betway",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://r1.betway.com?s=wgs20747&a=wgsaffad23933",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 135,
    isNew: false,
    bonus: {
      id: "15",
      title: "£/€30 Risk-Free Bet!",
      text: "Sign up to get your £/€30 free bet now! New customers only. Min deposit $/€10. Max Free Bet $/€30. First successful deposit matched only. 1x wagering at odds f 1.75+ to unlock Free Bet. T&Cs apply.",
    },
  },
  27: {
    idProvider: "27",
    logo: "27.png",
    WebName: "888sport",
    WebUrl: "888sport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.888sport.com/bet/?sr=399931&flag=0000",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 16,
    isNew: false,
    bonus: {
      id: "5",
      title: "Bet €10 Get €30!",
      text: "Place a €10 bet and get two €10 Free Bets and a €10 Free Bet for mobile! T&Cs Apply.",
    },
  },
  28: {
    idProvider: "28",
    logo: "28.png",
    WebName: "Ladbrokes",
    WebUrl: "ladbrokes",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://online.ladbrokes.com/promoRedirect?key=ej0xMzc4NzQzOSZsPTEzNzg3MzcxJnA9MzA1 ODA%3D",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 249,
    isNew: false,
    bonus: {
      id: "31",
      title: "£20 Free Bet!",
      text: "Bet £5 and get £20 FREE bet</b>!",
    },
  },
  29: {
    idProvider: "29",
    WebName: "Sunmaker",
    WebUrl: "sunmaker",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.sunmaker.com/en/Generic-Module.aspx?code=2164",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 370,
    isNew: false,
  },
  30: {
    idProvider: "30",
    logo: "30.png",
    WebName: "BoyleSports",
    WebUrl: "boylesports",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.boylesports.com/redirect.aspx?pid=2096&bid=1466",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 147,
    isNew: false,
    bonus: {
      id: "18",
      title: "Bet €10 Get €40!",
      text: "IRE/NI new customers only. €/£40 in FREE Bets (FB) as €/£30 in sports bets & a €/£10 casino bonus (CB). Min stake €/£10. Min odds Evs. FB applied on 1st settlement of any qualifying bet. FB 7 day expiry. 1 FB offer per customer, household & IP address only. Payment restrictions. 14 days to accept €/£10 CB, then active for 3 days. CB 5x wagering & max redeemable €/£100. Cashed out/Free Bets won’t apply. 30 days to qualify. 18+. T&Cs apply **18+",
    },
  },
  31: {
    idProvider: "31",
    logo: "31.png",
    WebName: "Everygame",
    WebUrl: "everygame",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.intertops.com/processing/clickthrgh.asp?btag=a_1798b_373",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 192,
    isNew: false,
    bonus: {
      id: "28",
      title: "$200 Sign-up Bonus!",
      text: "All new Intertops Sportsbook customers receive a fantastic 50% deposit bonus up to $200 for their first deposit!",
    },
  },
  32: {
    idProvider: "32",
    logo: "32.png",
    WebName: "Betclic",
    WebUrl: "betclic",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.beaffiliates.com/processing/clickthrgh.asp?btag=a_1219b_6121",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 71,
    isNew: false,
  },
  33: {
    idProvider: "33",
    logo: "33.png",
    WebName: "NordicBet",
    WebUrl: "nordicbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.nordicbetpartners.com/processing/clickthrgh.asp?btag=a_268b_183",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 290,
    isNew: false,
  },
  34: {
    idProvider: "34",
    logo: "34.png",
    WebName: "Bodog",
    WebUrl: "bodog",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.bettingpartners.com/_A97oqCpXs0-ikutM3oVpUGNd7ZgqdRLk/1/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "47",
    sr: 1,
    IsPremium: "n",
    sortKey: 144,
    isNew: false,
    bonus: {
      id: "146",
      title: "100% Sign Up Bonus!",
      text: "Join Bodog and get a 100% free bet bonus up to $100!",
    },
  },
  35: {
    idProvider: "35",
    logo: "35.png",
    WebName: "Eurobet",
    WebUrl: "eurobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://record.betpartners.it/_1tsFO53jMySc2UOZEZNknWNd7ZgqdRLk/18/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 187,
    isNew: false,
  },
  38: {
    idProvider: "38",
    logo: "38.png",
    WebName: "Sportingbet",
    WebUrl: "sportingbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partner.sbaffiliates.com/processing/clickthrgh.asp?btag=a_19792b_3423",
    DefaultBetslipUrl:
      "http://www.sportingbet.com/t/mini/betSlip.aspx?affiliate=ia_com&pname=a_48920b_680",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 345,
    isNew: false,
  },
  39: {
    idProvider: "39",
    logo: "39.png",
    WebName: "Betdaq",
    WebUrl: "betdaq",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "http://affiliate.cdn.betdaqaffiliates.com/redirect.aspx?pid=2160&bid=1562",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 79,
    isNew: false,
    bonus: {
      id: "106",
      title: "£20 Free Bet!",
      text: "Join now for £20 free bet!",
    },
  },
  40: {
    idProvider: "40",
    WebName: "Sportbet",
    WebUrl: "sportbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.sportbet.com/tracking/Affiliate.asp?AffID=AF0001629&mediaTypeID=220&AffUrlID=2135",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 343,
    isNew: false,
  },
  41: {
    idProvider: "41",
    logo: "41.png",
    WebName: "mybet",
    WebUrl: "mybet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.mybet.com/redirect.aspx?pid=5226&bid=1598",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 280,
    isNew: false,
    bonus: {
      id: "80",
      title: "€100 Sports Bet Bonus!",
      text: "Register with mybet Today and start with €100 bonus</b>!",
    },
  },
  43: {
    idProvider: "43",
    logo: "43.png",
    WebName: "Betsson",
    WebUrl: "betsson",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.affiliatelounge.com/_PQI2ELGFWby2bnhOaaLAUmNd7ZgqdRLk/5",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 125,
    isNew: false,
    bonus: {
      id: "92",
      title: "100% up to €100 + €10 Free Bet!",
      text: "Welcome Bonus 100% up to €100 + €10 Free Bet!",
    },
  },
  44: {
    idProvider: "44",
    logo: "44.png",
    WebName: "Betfair Exchange",
    WebUrl: "betfair-exchange",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "http://ads.betfair.com/redirect.aspx?pid=2045061&bid=8703",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 85,
    isNew: false,
    bonus: {
      id: "298",
      title: "Get a Free €20 Exchange Bet!",
      text: "New customers only, bet a min of €20 on the Exchange and if your first bet loses,Betfair willl refund you €20 in Cash. T&Cs apply.",
    },
  },
  45: {
    idProvider: "45",
    logo: "45.png",
    WebName: "Chance.cz",
    WebUrl: "chance-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.chance.cz/PartnerRedirectAction.do?pid=6921&sid=7961&bid=4665&tid=2702",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "62",
    sr: 1,
    IsPremium: "n",
    sortKey: 162,
    isNew: false,
    bonus: {
      id: "264",
      title: "Welcome Bonus up to 2.200 CZK!",
      text: "Make a first deposit and receive a welcome bonus up to 2.200 CZK!",
    },
  },
  46: {
    idProvider: "46",
    logo: "46.png",
    WebName: "iFortuna.cz",
    WebUrl: "ifortuna-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://clicks.fortunaaffiliates.com/afs/come.php?id=202&cid=572&atype=1&ctgid=3",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "1340025478",
    SetNew: "1",
    PreferredCountryID: "62",
    sr: 1,
    IsPremium: "n",
    sortKey: 230,
    isNew: false,
    bonus: {
      id: "273",
      title: "3000 CZK Bonus!",
      text: "Register a new account and receive a bonus up to 3000 CZK!",
    },
  },
  47: {
    idProvider: "47",
    logo: "47.png",
    WebName: "MAXITIP.cz",
    WebUrl: "maxitip-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.maxi-tip.cz/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "62",
    sr: 1,
    IsPremium: "n",
    sortKey: 269,
    isNew: false,
    bonus: {
      id: "301",
      title: "200% First Deposit Bonus!",
      text: "200% Bonus on first deposit up to 100.000 CZK!",
    },
  },
  49: {
    idProvider: "49",
    logo: "49.png",
    WebName: "Tipsport.cz",
    WebUrl: "tipsport-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.tipsport.cz/PartnerRedirectAction.do?pid=922&sid=982&bid=1701&tid=821",
    DefaultBetslipUrl:
      "https://www.tipsport.cz/CreateTicketAction.do?pid=922&sid=982&bid=1701&tid=821",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "62",
    sr: 1,
    IsPremium: "n",
    sortKey: 392,
    isNew: false,
    bonus: {
      id: "220",
      title: "Welcome Bonus up to 50.500 CZK!",
      text: "Make a deposit and receive a welcome bonus up to 50.000 CZK + 500 CZK in free bets!",
    },
  },
  53: {
    idProvider: "53",
    logo: "2.png",
    WebName: "bwin.it",
    WebUrl: "bwin-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://mediaserver.bwinpartypartners.it/renderBanner.do?zoneId=1664355",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 154,
    isNew: false,
    bonus: {
      id: "177",
      title: "30€ bonus!",
      text: "Register at bwin.it and the bookmaker will give you bonus up to 30€!",
    },
  },
  54: {
    idProvider: "54",
    WebName: "Bet24",
    WebUrl: "bet24",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "www.bet24.com",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 34,
    isNew: false,
  },
  55: {
    idProvider: "55",
    logo: "55.png",
    WebName: "Noxwin",
    WebUrl: "noxwin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://noxwin.com/promotions/sports-betting-bonus?btag=a3000566|p1568|u10967|l1097|b0",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 292,
    isNew: false,
  },
  56: {
    idProvider: "56",
    logo: "56.png",
    WebName: "188BET",
    WebUrl: "188bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.1babakr.com/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 6,
    isNew: false,
  },
  57: {
    idProvider: "57",
    logo: "57.png",
    WebName: "Jetbull",
    WebUrl: "jetbull",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://m.jetbull.com/sports/oddsportal_excl?btag=1332",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 241,
    isNew: false,
    bonus: {
      id: "161",
      title: "€100 Sign Up Bonus!",
      text: "Create a player’s account today and Jetbull will give you a 100% initial bonus of up to €100.",
    },
  },
  59: {
    idProvider: "59",
    WebName: "BetUS",
    WebUrl: "betus",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betus.com/ats/14941/sportsbook",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 132,
    isNew: false,
  },
  60: {
    idProvider: "60",
    logo: "60.png",
    WebName: "Paddy Power",
    WebUrl: "paddy-power",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://content.paddypower.com/ppc-pages/enhanced-campaign/affiliates/sportsbook/generic/250bundle.html?AFF_ID=10062728",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 301,
    isNew: false,
    bonus: {
      id: "58",
      title: "£30 in free bets!",
      text: "Place a £10 first bet with Paddy Power and get 3 free £10 bets</b>!",
    },
  },
  64: {
    idProvider: "64",
    WebName: "Sports Alive",
    WebUrl: "sports-alive",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.incomeaccess.com/processing/clickthrgh.asp?btag=a_18897b_8620",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 353,
    isNew: false,
  },
  67: {
    idProvider: "67",
    WebName: "Internet1x2",
    WebUrl: "internet1x2",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.internet1x2.com/?affid=11879&plang=1",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 235,
    isNew: false,
  },
  68: {
    idProvider: "68",
    WebName: "BetCRIS",
    WebUrl: "betcris",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betcris.com/?cmpid=9330_3",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 77,
    isNew: false,
    bonus: {
      id: "47",
      title: "15% New Player Bonus!",
      text: "Deposit $50 or more and get a 15% sign-up bonus up to $500!",
    },
  },
  69: {
    idProvider: "69",
    logo: "69.png",
    WebName: "Bookmaker",
    WebUrl: "bookmaker",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.bookmaker.eu/?cmpid=18389_7266",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 145,
    isNew: false,
  },
  70: {
    idProvider: "70",
    logo: "70.png",
    WebName: "Tipico",
    WebUrl: "tipico",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.tipico.com/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 386,
    isNew: false,
    bonus: {
      id: "108",
      title: "100% Bonus up to €100!",
      text: "Open an account with Tipico.com today and claim 100% bonus of up to €100!",
    },
  },
  71: {
    idProvider: "71",
    logo: "71.png",
    WebName: "Coral",
    WebUrl: "coral",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.coral.co.uk/processing/clickthrgh.asp?btag=a_76b_5215&aid=",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 170,
    isNew: false,
    bonus: {
      id: "75",
      title: "£20 Free Bet!",
      text: "Open an account with Coral to claim your £20 free bet.",
    },
  },
  72: {
    idProvider: "72",
    WebName: "bets4all",
    WebUrl: "bets4all",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.bets4all.com/bets/index?ref=oddsportal",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 118,
    isNew: false,
  },
  73: {
    idProvider: "73",
    logo: "73.png",
    WebName: "Leonbets",
    WebUrl: "leonbets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.leonbets.com/?wm=7191941",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 255,
    isNew: false,
    bonus: {
      id: "51",
      title: "100% First Deposit Bonus",
      text: "The first deposit you make will be prized with a 100% signup bonus - up to $100 or equivalent in account currency.",
    },
  },
  74: {
    idProvider: "74",
    logo: "74.png",
    WebName: "Skybet",
    WebUrl: "skybet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatehub.skybet.com/processing/clickthrgh.asp?btag=a_3806b_40",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 336,
    isNew: false,
  },
  75: {
    idProvider: "75",
    logo: "75.png",
    WebName: "SBOBET",
    WebUrl: "sbobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatesmedia.sbobet.com/redirect.aspx?pid=2256&bid=1564",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 330,
    isNew: false,
    bonus: {
      id: "209",
      title: "€200 Deposit Bonus!",
      text: "Get an extra 15% of your deposit up to a maximum of €200 from SBOBET! Promotion code: BON",
    },
  },
  76: {
    idProvider: "76",
    logo: "76.png",
    WebName: "BetVictor",
    WebUrl: "betvictor",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://service.bv-aff-trx.com/C.ashx?btag=a_28047b_11325c_&affid=8122&siteid=28047&adid=11325&c=",
    DefaultBetslipUrl: "",
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 133,
    isNew: false,
    bonus: {
      id: "60",
      title: "Bet £10 on Football. Get £40 in Free Bets!",
      text: "18+ New customers only. Opt in, bet £10 on any football market at minimum 1/1odds. No cash out. Get £40 in Free Bets on selected events. Free Bets expire in 7 days. Debitcard / Apple Pay payments only. T&Cs apply. begambleaware.org. Please gamble responsibly.",
    },
  },
  77: {
    idProvider: "77",
    WebName: "Players Only",
    WebUrl: "players-only",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.commissionaccount.com/processing/clickthrgh.asp?btag=a_9225b_532",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 317,
    isNew: false,
  },
  78: {
    idProvider: "78",
    logo: "78.png",
    WebName: "BetOnline",
    WebUrl: "betonline",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partners.commission.bz/processing/clickthrgh.asp?btag=a_661b_4",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 107,
    isNew: false,
    bonus: {
      id: "250",
      title: "50% Welcome Bonus up to $2 500!",
      text: 'Use promo code "NEW2500" on your first deposit and receive an exclusive 50% bonus!',
    },
  },
  79: {
    idProvider: "79",
    logo: "79.png",
    WebName: "BetDSI",
    WebUrl: "betdsi",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betdsi.com/?cmpid=9834_37",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 80,
    isNew: false,
  },
  80: {
    idProvider: "80",
    logo: "80.png",
    WebName: "12BET",
    WebUrl: "12bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://go.12bet.com/92037099/sportsbook/eneuro/index.html",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 4,
    isNew: false,
    bonus: {
      id: "164",
      title: "Bet £10 Get £5!",
      text: "Place £10 bet and receive a £5 free bet!",
    },
  },
  81: {
    idProvider: "81",
    WebName: "betoto",
    WebUrl: "betoto",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://adserver.itsfogo.com/click.aspx?zoneid=56858",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 109,
    isNew: false,
  },
  82: {
    idProvider: "82",
    WebName: "Heroes Sports",
    WebUrl: "heroes-sports",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.HeroesCasino.com/?refer=OddsPortal",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 226,
    isNew: false,
  },
  86: {
    idProvider: "86",
    WebName: "Bet7days",
    WebUrl: "bet7days",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.bet7days.com/?alt=e6a6a6c796dde6e5ebdf221b2b5eabd8",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 42,
    isNew: false,
  },
  87: {
    idProvider: "87",
    WebName: "digibet",
    WebUrl: "digibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.digibet.com/redirect.aspx?pid=2256&bid=1481",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 175,
    isNew: false,
  },
  88: {
    idProvider: "88",
    WebName: "Legends",
    WebUrl: "legends",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.thebannerzone.com/aw.aspx?B=5&A=932&Task=Click",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 253,
    isNew: false,
  },
  89: {
    idProvider: "89",
    WebName: "Sports-1",
    WebUrl: "sports-1",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.sports-1.com/aspx/affiliateStoreClick.aspx?ID_SC=663&ID_I=392",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 355,
    isNew: false,
  },
  90: {
    idProvider: "90",
    logo: "90.png",
    WebName: "BetGun",
    WebUrl: "betgun",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.betgun.com/en/index.php?ref_id=23510&typ_pp=0",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 91,
    isNew: false,
    bonus: {
      id: "72",
      title: "€62,5 Bonus Offer!",
      text: "Open an account with Betgun.com today and claim 50% deposit bonus.",
    },
  },
  91: {
    idProvider: "91",
    WebName: "BetPhoenix",
    WebUrl: "betphoenix",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betphoenixaffiliate.com/idevaffiliate.php?id=1748",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 111,
    isNew: false,
  },
  92: {
    idProvider: "92",
    logo: "92.png",
    WebName: "Island Casino",
    WebUrl: "island-casino",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.islandcasino.com/tracking/Affiliate.asp?AffID=AF0001629&mediaTypeID=220&AffUrlID=2135",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 240,
    isNew: false,
    bonus: {
      id: "74",
      title: "50% Bonus on your First Deposit!",
      text: "50% bonus of your qualifying deposit up to a maximum of $520!",
    },
  },
  93: {
    idProvider: "93",
    WebName: "The Greek",
    WebUrl: "the-greek",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.thegreek.com/dw/sportsbook.asp?ap=A154238",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 384,
    isNew: false,
    bonus: {
      id: "76",
      title: "100% Cash Bonus on your First Deposit!",
      text: "Sign up with The Greek today and receive a 100% cash bonus on your first deposit using any deposit method up to $100!",
    },
  },
  97: {
    idProvider: "97",
    WebName: "Stryyke",
    WebUrl: "stryyke",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.stryyke.com/?affiliateID=1027",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 366,
    isNew: false,
  },
  98: {
    idProvider: "98",
    logo: "98.png",
    WebName: "Superbahis",
    WebUrl: "superbahis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partner.superbahisaffiliates.com/processing/clickthrgh.asp?btag=a_45421b_9516",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "191",
    sr: 1,
    IsPremium: "n",
    sortKey: 374,
    isNew: false,
    bonus: {
      id: "213",
      title: "150 TL + 20 TL Bonus!",
      text: "Open an account and receive bonuses up to 150 TL and 20 TL Free bonus!",
    },
  },
  99: {
    idProvider: "99",
    WebName: "World Sports Exchange",
    WebUrl: "world-sports-exchange",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ms.wsex.com/mediaserver/handleClick?promotion-id=NDQ1",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 446,
    isNew: false,
  },
  100: {
    idProvider: "100",
    WebName: "Sportsbook",
    WebUrl: "sportsbook",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://Flash.wizardpost.info/sub.aspx?l=5297&lp=2503",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 359,
    isNew: false,
  },
  101: {
    idProvider: "101",
    logo: "101.png",
    WebName: "Paf",
    WebUrl: "paf",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.pafpartners.com/_qmnpiN7jdGvKgDnYQLIxcmNd7ZgqdRLk/5",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 303,
    isNew: false,
    bonus: {
      id: "187",
      title: "€20 Risk Free Bet!",
      text: "€20 free bet for all new registrants!",
    },
  },
  103: {
    idProvider: "103",
    logo: "103.png",
    WebName: "Redbet",
    WebUrl: "redbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.redbet.com/signup/?campaign_code=oddsportal1",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 322,
    isNew: false,
  },
  105: {
    idProvider: "105",
    WebName: "totepool",
    WebUrl: "totepool",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://banners.totesport.com/processing/clickthrgh.asp?btag=a_4461b_122",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 399,
    isNew: false,
  },
  106: {
    idProvider: "106",
    WebName: "betChronicle",
    WebUrl: "betchronicle",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://clkuk.tradedoubler.com/click?p(122907)a(1627525)g(18125288)",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 68,
    isNew: false,
  },
  107: {
    idProvider: "107",
    logo: "107.png",
    WebName: "Betboo",
    WebUrl: "betboo",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.betboopartners.com/_y7WdvZq28xKPb6HlHYETZ2Nd7ZgqdRLk/1/",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "191",
    sr: 1,
    IsPremium: "n",
    sortKey: 65,
    isNew: false,
  },
  108: {
    idProvider: "108",
    WebName: "Sports",
    WebUrl: "sports",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.dimecash.com/processing/clickthrgh.asp?btag=a_1301b_97",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 352,
    isNew: false,
  },
  109: {
    idProvider: "109",
    WebName: "Oddsmaker",
    WebUrl: "oddsmaker",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.oddsmaker.com/refer.php?ID=oddsportal",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 294,
    isNew: false,
  },
  110: {
    idProvider: "110",
    WebName: "Betonline247",
    WebUrl: "betonline247",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betonline247.com/livesport.php",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 108,
    isNew: false,
  },
  111: {
    idProvider: "111",
    WebName: "Instant Action Sports",
    WebUrl: "instant-action-sports",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.instantactionsports.com/affiliateprog/login_response.php?txtName=OP",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 233,
    isNew: false,
    bonus: {
      id: "110",
      title: "30% Free Play Bonus!",
      text: "Open an account with Instant Action Sports and get up to 30% bonus on your first deposit.",
    },
  },
  112: {
    idProvider: "112",
    logo: "112.png",
    WebName: "Betinternet",
    WebUrl: "betinternet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.betinternet.com/processing/clickthrgh.asp?btag=a_20719b_3299",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 99,
    isNew: false,
    bonus: {
      id: "123",
      title: "50% Cashmatch Bet Offer",
      text: "By opening an account and betting with betinternet.com, you will receive a 50% cashmatch bonus up to £25. Enter the bonus code: BETINT50",
    },
  },
  113: {
    idProvider: "113",
    WebName: "Betcruise",
    WebUrl: "betcruise",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://betcruise.com/#/?btag=28630",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 78,
    isNew: false,
  },
  114: {
    idProvider: "114",
    WebName: "Justbet",
    WebUrl: "justbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wgmg.co.cr/aspx/affiliateStoreClick.aspx?ID_SC=1835",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 243,
    isNew: false,
    bonus: {
      id: "139",
      title: "50% Sportsbook Offer",
      text: "Register with JustBet.com today and claim your Sportsbook offer - up to 50% of your initial deposit.",
    },
  },
  115: {
    idProvider: "115",
    WebName: "Bet770",
    WebUrl: "bet770",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://track.acf-webmaster.com/bet770/en/?idaffiliation=111105&member=acf",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 41,
    isNew: false,
  },
  118: {
    idProvider: "118",
    WebName: "Extrabet",
    WebUrl: "extrabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.extrabet.com/processing/clickthrgh.asp?btag=a_109b_43",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 196,
    isNew: false,
  },
  119: {
    idProvider: "119",
    logo: "119.png",
    WebName: "Goalwin",
    WebUrl: "goalwin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.goalwin.com/splash/generic_sport/tabid/399/language/en-US/Default.aspx?Campaign=4A03804B-D164-4EB0-87CD-F3A4CC934703",
    DefaultBetslipUrl: "",
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 214,
    isNew: false,
  },
  120: {
    idProvider: "120",
    logo: "120.png",
    WebName: "Circlebet",
    WebUrl: "circlebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.circlebet.com/Signup.aspx?Code=zI17Q2BZ",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 164,
    isNew: false,
  },
  121: {
    idProvider: "121",
    logo: "121.png",
    WebName: "Titanbet",
    WebUrl: "titanbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://online.titanbet.com/promoRedirect?key=ej0xNTczMjYwMDk1Jmw9MjE5MDY2MTA0MyZwPTk2MDAwOA%3D%3D",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 394,
    isNew: false,
    bonus: {
      id: "134",
      title: "Bet €25 get €25 Free!",
      text: "Join Titan Bet today and receive €/£25 free bet!",
    },
  },
  122: {
    idProvider: "122",
    WebName: "EurosportBET",
    WebUrl: "eurosportbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.eurosportbet.com/redirect.aspx?pid=2171&bid=1527",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 190,
    isNew: false,
  },
  123: {
    idProvider: "123",
    WebName: "Bet911",
    WebUrl: "bet911",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://utils.bet911.com/signup/playersignup.aspx?affid=68",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 44,
    isNew: false,
  },
  124: {
    idProvider: "124",
    logo: "124.png",
    WebName: "Luxbet",
    WebUrl: "luxbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.luxbet.com/processing/clickthrgh.asp?btag=a_123b_2",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 260,
    isNew: false,
    bonus: {
      id: "186",
      title: "First Deposit Bonus up to $150!",
      text: "Register new account. Deposit $50 and get $150 in Bonus Bets!",
    },
  },
  125: {
    idProvider: "125",
    logo: "125.png",
    WebName: "Bestbet",
    WebUrl: "bestbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.bestbet.com/?code=oddsportal",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 27,
    isNew: false,
  },
  127: {
    idProvider: "127",
    WebName: "Bestake",
    WebUrl: "bestake",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "http://www.bestake.com/affiliate.php?act=track&id=2523",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 26,
    isNew: false,
  },
  128: {
    idProvider: "128",
    logo: "128.png",
    WebName: "youwin",
    WebUrl: "youwin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.youwin.com/redirect.aspx?pid=2300&bid=2538",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 452,
    isNew: false,
  },
  129: {
    idProvider: "129",
    logo: "129.png",
    logo: "2.png",
    WebName: "bwin.fr",
    WebUrl: "bwin-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2010750",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 153,
    isNew: false,
    bonus: {
      id: "175",
      title: "€120 Bonus with bwin.fr!",
      text: "Place your first bet with minimun stake €5. If your bet lose you will receive Free Bet of the amount of your bet up to €120!",
    },
  },
  130: {
    idProvider: "130",
    WebName: "Tobet",
    WebUrl: "tobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.tobetpartners.com/processing/clickthrgh.asp?btag=a_2481b_1771",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 395,
    isNew: false,
  },
  131: {
    idProvider: "131",
    WebName: "EurosportBET.fr",
    WebUrl: "eurosportbet-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.eurosportbet.com/redirect.aspx?pid=3404&bid=1498",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 191,
    isNew: false,
  },
  132: {
    idProvider: "132",
    WebName: "SAjOO FR",
    WebUrl: "sajoo-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://adserver.itsfogo.com/click.aspx?zoneid=117961",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 327,
    isNew: false,
  },
  134: {
    idProvider: "134",
    logo: "134.png",
    WebName: "ParionsWeb.fr",
    WebUrl: "parionsweb-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ad.zanox.com/ppc/?17990421C133937056T",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 308,
    isNew: false,
  },
  135: {
    idProvider: "135",
    WebName: "betWize",
    WebUrl: "betwize",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.betwize.com/processing/clickthrgh.asp?btag=a_151b_5",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 139,
    isNew: false,
  },
  136: {
    idProvider: "136",
    WebName: "BetBeast",
    WebUrl: "betbeast",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 63,
    isNew: false,
  },
  137: {
    idProvider: "137",
    WebName: "Bet Victor",
    WebUrl: "bet-victor",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 28,
    isNew: false,
  },
  138: {
    idProvider: "138",
    WebName: "BetRedKings",
    WebUrl: "betredkings",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://site.betredkings.com/index.cgi?aname=oddsportal&zone_id=oddsportal",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 115,
    isNew: false,
    bonus: {
      id: "165",
      title: "€40 First Deposit Bonus!",
      text: "Register an account. Make your first deposit. Complete the wagering and the",
    },
  },
  139: {
    idProvider: "139",
    logo: "139.png",
    WebName: "France Pari",
    WebUrl: "france-pari",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliation.france-pari.fr/processing/clickthrgh.asp?btag=a_88b_32",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 207,
    isNew: false,
    bonus: {
      id: "216",
      title: "Sign up bonus up to 150€!",
      text: "Register new account and receive free bonuses up to 150€!",
    },
  },
  140: {
    idProvider: "140",
    logo: "140.png",
    WebName: "Betclic.it",
    WebUrl: "betclic-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betclic.it/partner.aspx?p=LIR3&z=c10&h=1",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 0,
    IsPremium: "n",
    sortKey: 74,
    isNew: false,
    bonus: {
      id: "178",
      title: "Deposit Bonus up to €30!",
      text: "Receive a bonus up to €30!",
    },
  },
  141: {
    idProvider: "141",
    logo: "141.png",
    WebName: "Betclic.fr",
    WebUrl: "betclic-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.betclic.fr/partner.aspx?p=FLS&z=c10&h=1",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 73,
    isNew: false,
    bonus: {
      id: "179",
      title: "€100 Bonus!",
      text: "Claim your first deposit bonus up to €100!",
    },
  },
  142: {
    idProvider: "142",
    logo: "142.png",
    WebName: "Bets10",
    WebUrl: "bets10",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 117,
    isNew: false,
  },
  143: {
    idProvider: "143",
    WebName: "Sportingbet.au",
    WebUrl: "sportingbet-au",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 346,
    isNew: false,
  },
  144: {
    idProvider: "144",
    WebName: "Partybets.fr",
    WebUrl: "partybets-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 310,
    isNew: false,
  },
  145: {
    idProvider: "145",
    WebName: "BetVictor.de",
    WebUrl: "betvictor-de",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 134,
    isNew: false,
  },
  146: {
    idProvider: "146",
    WebName: "AllYouBet",
    WebUrl: "allyoubet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.brightshare.com/clickscounter.php?compaign=2299&groupid=0&bTag=vbaff0523",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 19,
    isNew: false,
  },
  147: {
    idProvider: "147",
    logo: "147.png",
    WebName: "Dafabet",
    WebUrl: "dafabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ff.connextra.com/Dafabet/selector/click?client=Dafabet&placement=0x0_oddsportal2",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 172,
    isNew: false,
    bonus: {
      id: "214",
      title: "First Deposit Bonus up to RM 450!",
      text: "Claim 150% Bonus up to RM 450 on your First Deposit!",
    },
  },
  149: {
    idProvider: "149",
    logo: "149.png",
    WebName: "Interwetten.es",
    WebUrl: "interwetten-es",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatesys.interwetten.com/redirect.aspx?pid=8800&bid=6806",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "176",
    sr: 1,
    IsPremium: "n",
    sortKey: 237,
    isNew: false,
  },
  150: {
    idProvider: "150",
    logo: "150.png",
    WebName: "LBapuestas",
    WebUrl: "lbapuestas",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.score-affiliates.com/processing/clickthrgh.asp?btag=a_103425b_2727",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "176",
    sr: 1,
    IsPremium: "n",
    sortKey: 252,
    isNew: false,
    bonus: {
      id: "208",
      title: "50€ Free Bet!",
      text: "50€ free bet for all new users!",
    },
  },
  151: {
    idProvider: "151",
    WebName: "Miapuesta",
    WebUrl: "miapuesta",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partner.sbaffiliates.com/processing/clickthrgh.asp?btag=a_46235b_9574",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "176",
    sr: 1,
    IsPremium: "n",
    sortKey: 271,
    isNew: false,
  },
  152: {
    idProvider: "152",
    logo: "152.png",
    WebName: "Triobet",
    WebUrl: "triobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 405,
    isNew: false,
  },
  153: {
    idProvider: "153",
    logo: "153.png",
    WebName: "PMU FR",
    WebUrl: "pmu-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 1,
    IsPremium: "n",
    sortKey: 318,
    isNew: false,
  },
  154: {
    idProvider: "154",
    WebName: "32Red Bet",
    WebUrl: "32red-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://resources.32red.com/redirect.aspx?pid=20369&bid=3099",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 13,
    isNew: false,
    bonus: {
      id: "248",
      title: "£10 Free Bet!",
      text: "Simply place a bet of £10 or more on any event and 32 Red Bet give you a £10free bet!",
    },
  },
  155: {
    idProvider: "155",
    WebName: "Totosi",
    WebUrl: "totosi",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 404,
    isNew: false,
  },
  156: {
    idProvider: "156",
    WebName: "Getwin",
    WebUrl: "getwin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.getwin.com/redirect.aspx?pid=2309&bid=1504",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 210,
    isNew: false,
  },
  157: {
    idProvider: "157",
    logo: "157.png",
    logo: "5.png",
    WebName: "Unibet.it",
    WebUrl: "unibet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://dspk.kindredplc.com/redirect.aspx?pid=37494&bid=19479",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 411,
    isNew: false,
    bonus: {
      id: "198",
      title: "€10 Free Bet!",
      text: "Open new account and receive €10 free bet!",
    },
  },
  158: {
    idProvider: "158",
    logo: "158.png",
    WebName: "Sportsbet.com.au",
    WebUrl: "sportsbet-com-au",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 356,
    isNew: false,
  },
  159: {
    idProvider: "159",
    logo: "159.png",
    WebName: "Sisal",
    WebUrl: "sisal",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 335,
    isNew: false,
  },
  160: {
    idProvider: "160",
    logo: "157.png",
    WebName: "Unibet.fr",
    WebUrl: "unibet-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.unibet.fr/redirect.aspx?pid=6904&bid=1820",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "77",
    sr: 0,
    IsPremium: "n",
    sortKey: 410,
    isNew: false,
    bonus: {
      id: "197",
      title: "€100 Register Bonus!",
      text: "Register new account and receive €100 bonus!",
    },
  },
  161: {
    idProvider: "161",
    logo: "161.png",
    WebName: "NetBet",
    WebUrl: "netbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.livepartners.com/affiliate/tracking/click/?id=329347&bid=2169&lang=en&tag=139b_2",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 284,
    isNew: false,
    bonus: {
      id: "254",
      title: "£50 Deposit Bonus!",
      text: "Open an account and claim your £50 deposit bonus! Promocode: WELCOME50",
    },
  },
  162: {
    idProvider: "162",
    WebName: "STARLOTTOSPORT",
    WebUrl: "starlottosport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.starlottosport.com/Default.aspx?promoCode=Q7CAAAAAABGGS5TF",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 362,
    isNew: false,
  },
  163: {
    idProvider: "163",
    logo: "46.png",
    WebName: "eFortuna.pl",
    WebUrl: "efortuna-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "154",
    sr: 1,
    IsPremium: "n",
    sortKey: 184,
    isNew: false,
    bonus: {
      id: "290",
      title: "Win or refund of the stake up to PLN 600!",
      text: "If your first coupon is the winning one, you get the win, if you lose, we willrefund your contribution, up to a maximum of PLN 600!",
    },
  },
  164: {
    idProvider: "164",
    logo: "46.png",
    WebName: "iFortuna.sk",
    WebUrl: "ifortuna-sk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://clicks.fortunaaffiliates.com/afs/come.php?id=694&cid=732&ctgid=3&atype=1",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "171",
    sr: 1,
    IsPremium: "n",
    sortKey: 231,
    isNew: false,
    bonus: {
      id: "210",
      title: "Welcome Bonus up to €1.000!",
      text: "Open new account and receive €1.000 welcome bonus!",
    },
  },
  165: {
    idProvider: "165",
    logo: "165.png",
    WebName: "STS.pl",
    WebUrl: "sts-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1523872398",
    SetNew: "1",
    PreferredCountryID: "154",
    sr: 1,
    IsPremium: "n",
    sortKey: 369,
    isNew: false,
    bonus: {
      id: "310",
      title: "100% Bonus up to 1560 PLN!",
      text: "Register a new account and receive 60 PLN free bet and 100% welcome bonusup to 1500 PLN!",
    },
  },
  166: {
    idProvider: "166",
    WebName: "Nike",
    WebUrl: "nike",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 288,
    isNew: false,
  },
  167: {
    idProvider: "167",
    WebName: "Teletip",
    WebUrl: "teletip",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 380,
    isNew: false,
  },
  168: {
    idProvider: "168",
    WebName: "Totomix",
    WebUrl: "totomix",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 402,
    isNew: false,
  },
  169: {
    idProvider: "169",
    logo: "169.png",
    WebName: "Tipos",
    WebUrl: "tipos",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 390,
    isNew: false,
  },
  170: {
    idProvider: "170",
    WebName: "Milenium",
    WebUrl: "milenium",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 272,
    isNew: false,
  },
  171: {
    idProvider: "171",
    WebName: "Betako",
    WebUrl: "betako",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 48,
    isNew: false,
  },
  244: {
    idProvider: "244",
    logo: "244.png",
    WebName: "WagerWeb",
    WebUrl: "wagerweb",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.marketmediacenter.com/_0nTw5DpCkFMWqcfzuvZcQGNd7ZgqdRLk/1/",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 434,
    isNew: false,
  },
  293: {
    idProvider: "293",
    logo: "293.png",
    WebName: "Nike.sk",
    WebUrl: "nike-sk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.nike.sk/kurzovaPonuka/home?utm_source=oddsportal.com&utm_content=kurz&utm_campaign=ODDS",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "171",
    sr: 1,
    IsPremium: "n",
    sortKey: 289,
    isNew: false,
    bonus: {
      id: "293",
      title: "Welcome Bonus up to €1.000!",
      text: "100% first deposit bonus up to €1.000!",
    },
  },
  302: {
    idProvider: "302",
    logo: "302.png",
    WebName: "SBG Global",
    WebUrl: "sbg-global",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.sbgglobal.eu/wc/clicks.php?aff=ODDSPORTAL_225_142_32343",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 329,
    isNew: false,
    bonus: {
      id: "215",
      title: "30% Sign Up deposit bonus!",
      text: "30% Sign Up deposit bonus on a maximum deposit of $10,000 for sports betting!",
    },
  },
  322: {
    idProvider: "322",
    logo: "322.png",
    WebName: "Eurobet.it",
    WebUrl: "eurobet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://record.betpartners.it/_1tsFO53jMySc2UOZEZNknWNd7ZgqdRLk/18/",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1614100978",
    SetNew: "1",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 188,
    isNew: false,
    bonus: {
      id: "81",
      title: "€5 + €100 Welcome Bonus!",
      text: "€5 immediately upon deposit + up to €100 Bonus!",
    },
  },
  366: {
    idProvider: "366",
    WebName: "Victory Tip",
    WebUrl: "victory-tip",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 426,
    isNew: false,
  },
  367: {
    idProvider: "367",
    WebName: "Bet1128.it",
    WebUrl: "bet1128-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 33,
    isNew: false,
  },
  368: {
    idProvider: "368",
    WebName: "Globetsport",
    WebUrl: "globetsport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 213,
    isNew: false,
  },
  369: {
    idProvider: "369",
    WebName: "Admiral",
    WebUrl: "admiral",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 18,
    isNew: false,
  },
  370: {
    idProvider: "370",
    WebName: "ShilBet",
    WebUrl: "shilbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 334,
    isNew: false,
  },
  371: {
    idProvider: "371",
    WebName: "Kajot bet",
    WebUrl: "kajot-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 244,
    isNew: false,
  },
  372: {
    idProvider: "372",
    logo: "15.png",
    WebName: "WilliamHill.it",
    WebUrl: "williamhill-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.williamhill.it/redirect.aspx?pid=11695528&lpid=1486080757&bid=1486081145",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 436,
    isNew: false,
    bonus: {
      id: "217",
      title: "Welcome bonus up to €235!",
      text: "Bonus on your first deposit up to €235! Use promo code: ITA235",
    },
  },
  373: {
    idProvider: "373",
    logo: "373.png",
    WebName: "Top Bet",
    WebUrl: "top-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partner.tbaffiliate.com/PlayerRegisterRedirect.html?lid=1306&affid=1725&sourceid=2",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "200",
    sr: 1,
    IsPremium: "n",
    sortKey: 397,
    isNew: false,
    bonus: {
      id: "203",
      title: "50% Initial Deposit Bonus",
      text: "Deposit for the first time and TOP Bet will give you a huge 50% initial deposit bonus on your account instantly.",
    },
  },
  374: {
    idProvider: "374",
    WebName: "Interapostas",
    WebUrl: "interapostas",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatesys.interwetten.com/redirect.aspx?pid=8491&bid=2322",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "155",
    sr: 1,
    IsPremium: "n",
    sortKey: 234,
    isNew: false,
  },
  375: {
    idProvider: "375",
    logo: "1.png",
    WebName: "Interwetten.it",
    WebUrl: "interwetten-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliatesys.interwetten.it/redirect.aspx?pid=8507&bid=5341",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 238,
    isNew: false,
    bonus: {
      id: "218",
      title: "25% First Deposit Bonus up to €50!",
      text: "Deposit at least €10 into your betting account and receive a 25% bonus!",
    },
  },
  376: {
    idProvider: "376",
    logo: "376.png",
    WebName: "FortunaWin",
    WebUrl: "fortunawin",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://clicks.fortunaaffiliates.com/afs/come.php?id=711&cid=597&ctgid=3&atype=1",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 205,
    isNew: false,
  },
  377: {
    idProvider: "377",
    logo: "377.png",
    WebName: "Bovada",
    WebUrl: "bovada",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://record.bettingpartners.com/_A97oqCpXs08SVaOUX6eQj2Nd7ZgqdRLk/8/",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "200",
    sr: 1,
    IsPremium: "n",
    sortKey: 146,
    isNew: false,
    bonus: {
      id: "207",
      title: "50% Free Bet!",
      text: "Open a betting account and grab an instant 50% free bet Bonus on your first deposit, up to $250!",
    },
  },
  378: {
    idProvider: "378",
    logo: "378.png",
    WebName: "TonyBet",
    WebUrl: "tonybet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: " https://tonybet.com/landing-page/welcome-sportsbook/?trc=28_31_0",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 396,
    isNew: false,
    bonus: {
      id: "211",
      title: "100% Bonus to New Customers up to €200!",
      text: 'Sign up at TonyBet. Make a deposit entering bonus code: "WC2018". Get your 100% bonus up to €200!',
    },
  },
  379: {
    idProvider: "379",
    logo: "379.png",
    WebName: "PaddyPower.it",
    WebUrl: "paddypower-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://media.paddypower.com/redirect.aspx?pid=10069244&bid=6953",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 302,
    isNew: false,
    bonus: {
      id: "219",
      title: "€5 Deposit Bonus!",
      text: "First deposit bonus up to €5!",
    },
  },
  380: {
    idProvider: "380",
    WebName: "Offsidebet",
    WebUrl: "offsidebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.o-networkaffiliates.com/redirect.aspx?pid=5175&bid=2921",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 296,
    isNew: false,
  },
  381: {
    idProvider: "381",
    logo: "381.png",
    WebName: "Marathonbet",
    WebUrl: "marathonbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://aff.mediarotate.com/click.aspx?bid=604_524",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 262,
    isNew: false,
  },
  382: {
    idProvider: "382",
    logo: "382.png",
    WebName: "Forvetbet",
    WebUrl: "forvetbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 206,
    isNew: false,
  },
  383: {
    idProvider: "383",
    logo: "383.png",
    WebName: "ComeOn",
    WebUrl: "comeon",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 167,
    isNew: false,
    bonus: {
      id: "274",
      title: "100% up to €25 on Sports!",
      text: "Make your 1st deposit at ComeOn and get a 100% Welcome Bonus up to €25!",
    },
  },
  384: {
    idProvider: "384",
    logo: "3.png",
    WebName: "bet-at-home.it",
    WebUrl: "bet-at-home-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://wlbetathome.adsrv.eacdn.com/wl/clk/?btag=a_81455b_33464&aid=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 32,
    isNew: false,
  },
  385: {
    idProvider: "385",
    WebName: "SportsBettingOnline",
    WebUrl: "sportsbettingonline",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.sportsbettingonline.ag/vrb/affiliates/redir.php?pid=1360&aid=1635",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "200",
    sr: 1,
    IsPremium: "n",
    sortKey: 358,
    isNew: false,
    bonus: {
      id: "223",
      title: "10% Cashback Weekly",
      text: "Sign-up and get 10% Weekly Cashback whether you win or loss!",
    },
  },
  386: {
    idProvider: "386",
    logo: "386.png",
    WebName: "Ucabet",
    WebUrl: "ucabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.ucabet.co/marketing/click/6888/2/",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 408,
    isNew: false,
  },
  387: {
    idProvider: "387",
    logo: "387.png",
    WebName: "SportsBetting",
    WebUrl: "sportsbetting",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partners.commission.bz/processing/clickthrgh.asp?btag=a_35848b_1036",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 357,
    isNew: false,
    bonus: {
      id: "226",
      title: "50% Free Play Bonus!",
      text: "50% first deposit bonus! Promo code: SBFIRST",
    },
  },
  388: {
    idProvider: "388",
    WebName: "90dakika",
    WebUrl: "90dakika",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 17,
    isNew: false,
  },
  389: {
    idProvider: "389",
    WebName: "Heaven Bet",
    WebUrl: "heaven-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 224,
    isNew: false,
  },
  390: {
    idProvider: "390",
    logo: "390.png",
    WebName: "Matchbook",
    WebUrl: "matchbook",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "http://affiliate.matchbook.com/processing/clickthrgh.asp?btag=a_24b_365&aid=",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1366203090",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 268,
    isNew: false,
    bonus: {
      id: "224",
      title: "0% Commission on all sports",
      text: "Take advantage of 0% commission on all sports for 30 days when you first sign up with Matchbook! Promo code: COMMFREE",
    },
  },
  391: {
    idProvider: "391",
    logo: "391.png",
    WebName: "WSBets",
    WebUrl: "wsbets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 449,
    isNew: false,
  },
  392: {
    idProvider: "392",
    logo: "2.png",
    WebName: "bwin.es",
    WebUrl: "bwin-es",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://sports.bwin.es/es/sports?wm=3587166&zoneId=1140129",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "176",
    sr: 1,
    IsPremium: "n",
    sortKey: 152,
    isNew: false,
  },
  393: {
    idProvider: "393",
    logo: "393.png",
    WebName: "Oddsring",
    WebUrl: "oddsring",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.oddsring.com/?wm=8321873",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 295,
    isNew: false,
    bonus: {
      id: "231",
      title: "€200 First Deposit Bonus!",
      text: "200% first deposit bonus up to €200!",
    },
  },
  394: {
    idProvider: "394",
    logo: "394.png",
    WebName: "Tipico.it",
    WebUrl: "tipico-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.tipico.it/?affiliateId=319419&bannerId=80240",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 387,
    isNew: false,
    bonus: {
      id: "233",
      title: "100% Bonus up to €50!",
      text: "Open an account with Tipico.it today and claim 100% bonus of up to €50!",
    },
  },
  395: {
    idProvider: "395",
    logo: "395.png",
    WebName: "Tempobet",
    WebUrl: "tempobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://tempobanners.com/affiliates/4816982",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 381,
    isNew: false,
  },
  396: {
    idProvider: "396",
    logo: "396.png",
    WebName: "Oddset",
    WebUrl: "oddset",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 293,
    isNew: false,
  },
  397: {
    idProvider: "397",
    logo: "397.png",
    WebName: "Winline",
    WebUrl: "winline",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://track.winlinebet.ru/C.ashx?btag=a_203b_39c_&affid=8&siteid=203&adid=39&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "158",
    sr: 0,
    IsPremium: "n",
    sortKey: 437,
    isNew: false,
  },
  398: {
    idProvider: "398",
    logo: "161.png",
    WebName: "NetBet.fr",
    WebUrl: "netbet-fr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 285,
    isNew: false,
  },
  399: {
    idProvider: "399",
    logo: "399.png",
    WebName: "Winner",
    WebUrl: "winner",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://online.winner.co.uk/promoRedirect?member=oddsportal&campaign=DEFAULT&channel=DEFAULT&zone=54241659&lp=0",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 443,
    isNew: false,
    bonus: {
      id: "235",
      title: "100% Bonus up to £200!",
      text: "Register with Winner.com and and get bonus up to £200!",
    },
  },
  400: {
    idProvider: "400",
    logo: "400.png",
    WebName: "HiperBet",
    WebUrl: "hiperbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 227,
    isNew: false,
  },
  401: {
    idProvider: "401",
    WebName: "mybet.it",
    WebUrl: "mybet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 281,
    isNew: false,
  },
  402: {
    idProvider: "402",
    logo: "128.png",
    WebName: "Hepsibahis",
    WebUrl: "hepsibahis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 225,
    isNew: false,
  },
  403: {
    idProvider: "403",
    logo: "403.png",
    WebName: "Betrally",
    WebUrl: "betrally",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partners.betrally.com/processing/clickthrgh.asp?btag=a_22967b_1184",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 113,
    isNew: false,
    bonus: {
      id: "236",
      title: "€100 Welcome Bonus!",
      text: "Fantastic 100% first deposit bonus up to £/€100! Bonus code: 100RALLY",
    },
  },
  404: {
    idProvider: "404",
    // logo: "404.png",
    WebName: "Seaniemac",
    WebUrl: "seaniemac",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://ads.seaniemac.com/redirect.aspx?pid=32712&bid=1942",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 332,
    isNew: false,
  },
  405: {
    idProvider: "405",
    // logo: "405.png",
    WebName: "Bet9",
    WebUrl: "bet9",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 43,
    isNew: false,
  },
  406: {
    idProvider: "406",
    logo: "406.png",
    WebName: "Sportium.es",
    WebUrl: "sportium-es",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://online.sportium.es/promoRedirect?key=ej0xMzU2ODA5NyZsPTEzNTY4MDk0JnA9MTENTUy",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "176",
    sr: 1,
    IsPremium: "n",
    sortKey: 350,
    isNew: false,
  },
  407: {
    idProvider: "407",
    logo: "407.png",
    WebName: "Vistabet.gr",
    WebUrl: "vistabet-gr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 428,
    isNew: false,
  },
  408: {
    idProvider: "408",
    logo: "408.png",
    WebName: "Sportingbet.gr",
    WebUrl: "sportingbet-gr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 348,
    isNew: false,
  },
  409: {
    idProvider: "409",
    logo: "409.png",
    WebName: "Stoiximan.gr",
    WebUrl: "stoiximan-gr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 365,
    isNew: false,
  },
  410: {
    idProvider: "410",
    logo: "410.png",
    WebName: "Dhoze",
    WebUrl: "dhoze",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 174,
    isNew: false,
  },
  411: {
    idProvider: "411",
    logo: "49.png",
    WebName: "Tipsport.sk",
    WebUrl: "tipsport-sk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.tipsport.sk/PartnerRedirectAction.do?pid=1761&sid=1861&bid=903&tid=402",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "171",
    sr: 1,
    IsPremium: "n",
    sortKey: 393,
    isNew: false,
    bonus: {
      id: "237",
      title: "€20 Free Bet + Deposit Bonus up to €4.050!",
      text: "Register a new account, make a deposit and receive €50 free bet and 100% deposit bonus up to €4.000!",
    },
  },
  412: {
    idProvider: "412",
    WebName: "BET2BE",
    WebUrl: "bet2be",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.bet2be.com/?referal=MWtJaDdWNGNlK3RkbERma1J5NGdRdz09",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 35,
    isNew: false,
    bonus: {
      id: "249",
      title: "100 GBP Welcome Bonus for All New Users!",
      text: "Receive up to 15% from the first deposit!",
    },
  },
  413: {
    idProvider: "413",
    logo: "413.png",
    WebName: "GoBetGo",
    WebUrl: "gobetgo",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.gobetgo.com/tracking?affuserid=4179&bannerid=29",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 215,
    isNew: false,
    bonus: {
      id: "256",
      title: "€50 Welcome Bonus!",
      text: "20% welcome bonus up to €50 (or currency equivalent) on your first deposit.Bonus code: 50SPORTS",
    },
  },
  414: {
    idProvider: "414",
    logo: "414.png",
    WebName: "SAZKAbet.cz",
    WebUrl: "sazkabet-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "62",
    sr: 1,
    IsPremium: "n",
    sortKey: 328,
    isNew: false,
    bonus: {
      id: "287",
      title: "Up to 5,500 Kč when you register with Sazkabet",
      text: "Register a new account and receive bonuses up to 5,500 Kč!",
    },
  },
  415: {
    idProvider: "415",
    logo: "415.png",
    WebName: "Dashbet",
    WebUrl: "dashbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://dashbet.com/?btag=1016_101",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 173,
    isNew: false,
    bonus: {
      id: "253",
      title: "£/€250 Initial Cash Bonus!",
      text: "Open an account and claim your £/€250 deposit bonus! Promocode: S250",
    },
  },
  416: {
    idProvider: "416",
    logo: "416.png",
    WebName: "18bet",
    WebUrl: "18bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.18betaffiliates.com/processing/clickthrgh.asp?btag=a_363b_179&aid=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 7,
    isNew: false,
    bonus: {
      id: "252",
      title: "100% Bonus up to 100€!",
      text: "100% first deposit bonus up to 100€! Promocode: WB100",
    },
  },
  417: {
    idProvider: "417",
    logo: "417.png",
    WebName: "1xBet",
    WebUrl: "1xbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://refparrknf.top/L?tag=s_143m_355c__[]general[]_d32057_l24906_banner&site=143&ad=355",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 9,
    isNew: false,
    bonus: {
      id: "262",
      title: "100% Welcome Bonus up to €100!",
      text: "Register a new account, make your first deposit and get bonus of 100% up to€100!",
    },
  },
  418: {
    idProvider: "418",
    logo: "418.png",
    WebName: "Vernons",
    WebUrl: "vernons",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliates.netplayaffiliates.com/processing/clickthrgh.asp?btag=a_7733b_1154&aid=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 425,
    isNew: false,
  },
  419: {
    idProvider: "419",
    logo: "16.png",
    WebName: "bet365.it",
    WebUrl: "bet365-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.bet365.it/olp/open-account?affiliate=365_354149",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 0,
    IsPremium: "n",
    sortKey: 37,
    isNew: false,
    bonus: {
      id: "258",
      title: "100% Bonus up to €100!",
      text: "Open an account with bet365.it Today and claim your 100% Deposit Bonus.T&Cs apply.",
    },
  },
  420: {
    idProvider: "420",
    logo: "420.png",
    WebName: "WonOdds",
    WebUrl: "wonodds",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 445,
    isNew: false,
  },
  421: {
    idProvider: "421",
    WebName: "Betadonis",
    WebUrl: "betadonis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://betadonis22.com/?btag=a3000582|p3486|u2014914|l0|b0",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 46,
    isNew: false,
    bonus: {
      id: "259",
      title: "25% First Deposit Bonus!",
      text: "25% first deposit bonus. The qualifying deposit is from 5EUR up to 350EUR!",
    },
  },
  422: {
    idProvider: "422",
    logo: "422.png",
    WebName: "5plusbet",
    WebUrl: "5plusbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://5plusbet.com/?btag=1293_101",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 15,
    isNew: false,
  },
  423: {
    idProvider: "423",
    logo: "161.png",
    WebName: "NetBet.it",
    WebUrl: "netbet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 286,
    isNew: false,
  },
  424: {
    idProvider: "424",
    logo: "424.png",
    WebName: "138",
    WebUrl: "138",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wl138com.adsrv.eacdn.com/C.ashx?btag=a_211202b_49c_&affid=211202&siteid=211202&adid=49&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 5,
    isNew: false,
    bonus: {
      id: "261",
      title: "Matched First Bet up to £25!",
      text: "Receive a 100% free bet bonus for your stake when your first bet is £10 or over!The offer is available to residents of the UK and Ireland ONLY.",
    },
  },
  425: {
    idProvider: "425",
    logo: "425.png",
    WebName: "RealDealBet",
    WebUrl: "realdealbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://partners.realdealbet.com/processing/clickthrgh.asp?btag=a_30489b_1857",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 321,
    isNew: false,
    bonus: {
      id: "263",
      title: "€100 Welcome Bonus!",
      text: "Make your first shot count with a 100% up to €100 bonus on your first deposit.Use bonus code RDB100",
    },
  },
  426: {
    idProvider: "426",
    logo: "426.png",
    WebName: "OtoBet",
    WebUrl: "otobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://otobet.com/?ref=3738536769180700",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 300,
    isNew: false,
    bonus: {
      id: "265",
      title: "110% Welcome Bonus!",
      text: "Claim 110% welcome bonus up to 200 EURO/USD!",
    },
  },
  427: {
    idProvider: "427",
    logo: "427.png",
    WebName: "VitalBet",
    WebUrl: "vitalbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://affiliate.vitalbet.com/tracking?affuserid=8299&bannerid=105",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 429,
    isNew: false,
    bonus: {
      id: "266",
      title: "50 EUR/USD Free Bet!",
      text: "When you place your first bet of €10/$10 or more Vitalbet will refund your bet ifit loses.",
    },
  },
  428: {
    idProvider: "428",
    logo: "428.png",
    WebName: "Kroon Casino",
    WebUrl: "kroon-casino",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 246,
    isNew: false,
  },
  429: {
    idProvider: "429",
    logo: "429.png",
    WebName: "Betfair",
    WebUrl: "betfair",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1506606154",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 84,
    isNew: false,
    bonus: {
      id: "305",
      title: "Deposit €20 play for €40!",
      text: "New customers offer. Deposit €20, play with €40 on any Sportsbook market!T&Cs apply.",
    },
  },
  430: {
    idProvider: "430",
    logo: "430.png",
    WebName: "Winmasters",
    WebUrl: "winmasters",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 441,
    isNew: false,
  },
  431: {
    idProvider: "431",
    logo: "431.png",
    WebName: "SuperAposta",
    WebUrl: "superaposta",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 373,
    isNew: false,
  },
  432: {
    idProvider: "432",
    logo: "432.png",
    WebName: "Bettilt",
    WebUrl: "bettilt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 131,
    isNew: false,
    bonus: {
      id: "323",
      title: "100% up to 500€ on your First Deposit + 5€ in Free bet!",
      text: "Register now and enjoy 100% first deposit bonus up to €500 + 5€ Free bet!",
    },
  },
  433: {
    idProvider: "433",
    logo: "433.png",
    WebName: "Guts",
    WebUrl: "guts",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 222,
    isNew: false,
  },
  434: {
    idProvider: "434",
    logo: "434.png",
    WebName: "Jojobet",
    WebUrl: "jojobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wljojobet.adsrv.eacdn.com/C.ashx?btag=a_67b_7c_&affid=64&siteid=67&adid=7&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 242,
    isNew: false,
  },
  435: {
    idProvider: "435",
    WebName: "SportingbetMaster",
    WebUrl: "sportingbetmaster",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 349,
    isNew: false,
  },
  436: {
    idProvider: "436",
    logo: "436.png",
    WebName: "Bet-52",
    WebUrl: "bet-52",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://bet-52.com/?btag=17227",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 29,
    isNew: false,
    bonus: {
      id: "271",
      title: "100% Welcome Bonus up to 250€!",
      text: "Receive 100% Welcome bonus when you register with bet-52!",
    },
  },
  437: {
    idProvider: "437",
    logo: "437.png",
    WebName: "BizimBahis",
    WebUrl: "bizimbahis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 142,
    isNew: false,
  },
  438: {
    idProvider: "438",
    logo: "46.png",
    WebName: "Fortuna.ro",
    WebUrl: "fortuna-ro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 204,
    isNew: false,
  },
  439: {
    idProvider: "439",
    logo: "439.png",
    WebName: "Restbet",
    WebUrl: "restbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 323,
    isNew: false,
  },
  440: {
    idProvider: "440",
    logo: "440.png",
    WebName: "Marcaapuestas",
    WebUrl: "marcaapuestas",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 265,
    isNew: false,
  },
  441: {
    idProvider: "441",
    logo: "441.png",
    WebName: "Tipobet",
    WebUrl: "tipobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 389,
    isNew: false,
  },
  442: {
    idProvider: "442",
    logo: "442.png",
    WebName: "VonBets",
    WebUrl: "vonbets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 432,
    isNew: false,
    bonus: {
      id: "275",
      title: "100% Matched Deposit Bonus!",
      text: "100% matched deposit bonus up to a maximum €100/$100 on your firsdeposit!",
    },
  },
  443: {
    idProvider: "443",
    logo: "443.png",
    WebName: "BetWorld",
    WebUrl: "betworld",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 140,
    isNew: false,
    bonus: {
      id: "316",
      title: "Betworld 100% Welcome bonus up to €100!",
      text: "Register a new account and receive 100% in bonuses up to €100!",
    },
  },
  445: {
    idProvider: "445",
    logo: "430.png",
    WebName: "Sekabet",
    WebUrl: "sekabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://sekabet101.com/en/security/register?refcode=FC316EE9E43947BDBEF0630C02A10308",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 333,
    isNew: false,
    bonus: {
      id: "276",
      title: "€50 Sportsbook Welcome Bonus!",
      text: "100% welcome bonus up to €50!",
    },
  },
  446: {
    idProvider: "446",
    logo: "446.png",
    WebName: "BetOlimp",
    WebUrl: "betolimp",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wlbetolimp.adsrv.eacdn.com/C.ashx?btag=a_12b_20c_&affid=12&siteid=12&adid=20&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 105,
    isNew: false,
    bonus: {
      id: "277",
      title: "€100 Bonus!",
      text: "Get free bet up to €100!",
    },
  },
  447: {
    idProvider: "447",
    logo: "140.png",
    WebName: "Betclic.pt",
    WebUrl: "betclic-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 76,
    isNew: false,
  },
  448: {
    idProvider: "448",
    logo: "448.png",
    WebName: "Intragame",
    WebUrl: "intragame",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wlintragame.adsrv.eacdn.com/C.ashx?btag=a_1666b_406c_&affid=1216&siteid=1666&adid=406&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 239,
    isNew: false,
    bonus: {
      id: "278",
      title: "€50 Welcome Bonus!",
      text: "Register a new account and get 100% welcome bonus up to €50!",
    },
  },
  449: {
    idProvider: "449",
    logo: "449.png",
    WebName: "betcart",
    WebUrl: "betcart",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wlbetcart.adsrv.eacdn.com/C.ashx?btag=a_204b_369c_&affid=187&siteid=204&adid=369&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 67,
    isNew: false,
    bonus: {
      id: "280",
      title: "50% Welcome Bonus!",
      text: '50% first deposit bonus offer up to €250. Just remember to enter the bonuscode "BC50"!',
    },
  },
  450: {
    idProvider: "450",
    logo: "450.png",
    WebName: "Grandbetting",
    WebUrl: "grandbetting",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 218,
    isNew: false,
  },
  451: {
    idProvider: "451",
    logo: "451.png",
    WebName: "Vulkan Bet",
    WebUrl: "vulkan-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://trafficfam.net/l/627cfa3afaee8a441f348357",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 433,
    isNew: false,
    bonus: {
      id: "281",
      title: "100% Bonus Up To €150!",
      text: "Deposit €10 or more and get 100% bonus up to €150!",
    },
  },
  452: {
    idProvider: "452",
    WebName: "BetEast",
    WebUrl: "beteast",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://www.beteastaffiliates.eu/tracking?affuserid=74904&bannerid=1310",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 81,
    isNew: false,
  },
  453: {
    idProvider: "453",
    logo: "453.png",
    WebName: "1xStavka.ru",
    WebUrl: "1xstavka-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://aff1xstavka.top/L?tag=s_35297m_1341c_&site=35297&ad=1341",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "158",
    sr: 1,
    IsPremium: "n",
    sortKey: 11,
    isNew: false,
  },
  454: {
    idProvider: "454",
    logo: "454.png",
    WebName: "Winline.ru",
    WebUrl: "winline-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "158",
    sr: 1,
    IsPremium: "n",
    sortKey: 438,
    isNew: false,
  },
  455: {
    idProvider: "455",
    logo: "455.png",
    WebName: "Leon.ru",
    WebUrl: "leon-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.leon.ru/?wm=62870829",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "158",
    sr: 0,
    IsPremium: "n",
    sortKey: 254,
    isNew: false,
    bonus: {
      id: "291",
      title: "100% Bonus up to 20.000 Rubles.",
      text: "Make a first deposit and receive 100% bonus up to 20.000 rubles.",
    },
  },
  456: {
    idProvider: "456",
    logo: "456.png",
    WebName: "Riccobet",
    WebUrl: "riccobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 324,
    isNew: false,
  },
  457: {
    idProvider: "457",
    logo: "457.png",
    WebName: "Luckia.es",
    WebUrl: "luckia-es",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 258,
    isNew: false,
  },
  458: {
    idProvider: "458",
    WebName: "BetolimpMaster",
    WebUrl: "betolimpmaster",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 106,
    isNew: false,
  },
  459: {
    idProvider: "459",
    logo: "2.png",
    WebName: "bwin.pt",
    WebUrl: "bwin-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 155,
    isNew: false,
  },
  460: {
    idProvider: "460",
    logo: "460.png",
    WebName: "Tipbet",
    WebUrl: "tipbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 385,
    isNew: false,
    bonus: {
      id: "282",
      title: "200% Welcome Bonus!",
      text: "200% bonus up to €50 on the first deposit!",
    },
  },
  461: {
    idProvider: "461",
    logo: "461.png",
    WebName: "MrRingo",
    WebUrl: "mrringo",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 278,
    isNew: false,
    bonus: {
      id: "283",
      title: "€100 Sports Betting Bonus!",
      text: "100% deposit bonus up to €100 on the first deposit!",
    },
  },
  462: {
    idProvider: "462",
    logo: "462.png",
    WebName: "iForBet.pl",
    WebUrl: "iforbet-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "154",
    sr: 1,
    IsPremium: "n",
    sortKey: 229,
    isNew: false,
    bonus: {
      id: "292",
      title: "100% Bonus up to 600 PLN!",
      text: "Make a first deposit and receive 100% bonus up to 600 PLN! Use promo code:score",
    },
  },
  463: {
    idProvider: "463",
    WebName: "Tiplix",
    WebUrl: "tiplix",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 388,
    isNew: false,
  },
  464: {
    idProvider: "464",
    logo: "464.png",
    WebName: "Bethard",
    WebUrl: "bethard",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 92,
    isNew: false,
    bonus: {
      id: "285",
      title: "Welcome Bonus Up to €100!",
      text: "100% sport bonus up to €100!",
    },
  },
  465: {
    idProvider: "465",
    logo: "465.png",
    WebName: "Winmasters.ro",
    WebUrl: "winmasters-ro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 442,
    isNew: false,
  },
  466: {
    idProvider: "466",
    logo: "466.png",
    WebName: "Betebet",
    WebUrl: "betebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 82,
    isNew: false,
  },
  467: {
    idProvider: "467",
    logo: "467.png",
    WebName: "Planetwin365",
    WebUrl: "planetwin365",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1614097498",
    SetNew: "1",
    PreferredCountryID: "98",
    sr: 1,
    IsPremium: "n",
    sortKey: 316,
    isNew: false,
    bonus: {
      id: "319",
      title: "Welcome Bonus up to €365!",
      text: "Deposit at least €10 and receive a 100% bonus on your deposit up to €365!",
    },
  },
  468: {
    idProvider: "468",
    logo: "468.png",
    WebName: "Coolbet",
    WebUrl: "coolbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 168,
    isNew: false,
  },
  469: {
    idProvider: "469",
    logo: "469.png",
    WebName: "Babibet",
    WebUrl: "babibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 23,
    isNew: false,
    bonus: {
      id: "307",
      title: "50% up to €50 Welcome Bonus!",
      text: "Claim your personal 50% cash boost up to €50!",
    },
  },
  470: {
    idProvider: "470",
    logo: "470.png",
    WebName: "Marsbet",
    WebUrl: "marsbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://mobile.marsbet.com/",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1642258259",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 267,
    isNew: false,
    bonus: {
      id: "289",
      title: "Make your bet and get 10% of the amount back in cash!",
      text: "Every day, 10% of the first coupon you make on the New Wisegaming tab isreturned to you as CASH!",
    },
  },
  471: {
    idProvider: "471",
    logo: "417.png",
    WebName: "1xBet.kz",
    WebUrl: "1xbet-kz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 10,
    isNew: false,
  },
  472: {
    idProvider: "472",
    logo: "472.png",
    WebName: "Totolotek.pl",
    WebUrl: "totolotek-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://wlcashpointpartners.adsrv.eacdn.com/C.ashx?btag=a_4194b_690c_&affid=2794&siteid=4194&adid=690&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "154",
    sr: 1,
    IsPremium: "n",
    sortKey: 401,
    isNew: false,
    bonus: {
      id: "294",
      title: "100% First Deposit Bonus!",
      text: "100% first deposit bonus up to 500 PLN zł and 20 PLN free bet! ODDSPORTAL",
    },
  },
  473: {
    idProvider: "473",
    logo: "473.png",
    WebName: "ScandiBet",
    WebUrl: "scandibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 331,
    isNew: false,
    bonus: {
      id: "295",
      title: "50% up to €100 on Sports!",
      text: "Make your first deposit and receive 50% bonus up to €100!",
    },
  },
  474: {
    idProvider: "474",
    WebName: "TestingOnly",
    WebUrl: "testingonly",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 383,
    isNew: false,
  },
  475: {
    idProvider: "475",
    logo: "475.png",
    WebName: "Betser",
    WebUrl: "betser",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 124,
    isNew: false,
    bonus: {
      id: "296",
      title: "Double your deposit!",
      text: "Betser.com will match your first deposit up to €100!",
    },
  },
  476: {
    idProvider: "476",
    logo: "476.png",
    WebName: "Asianodds",
    WebUrl: "asianodds",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 22,
    isNew: false,
    bonus: {
      id: "297",
      title: "20% up to 400€ Welcome Bonus!",
      text: "Sign up now! Earn up to 400€ welcome bonus with Asianodds. Promo code:ACWELCOME400",
    },
  },
  477: {
    idProvider: "477",
    logo: "477.png",
    WebName: "Betmoon",
    WebUrl: "betmoon",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 104,
    isNew: false,
  },
  478: {
    idProvider: "478",
    logo: "478.png",
    WebName: "Dotabet",
    WebUrl: "dotabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://dotabet1.com/#/?btag=30535",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 179,
    isNew: false,
    bonus: {
      id: "299",
      title: "100% Welcome Bonus!",
      text: "100% Bonus on first deposit up to 75 Euros!",
    },
  },
  479: {
    idProvider: "479",
    logo: "479.png",
    WebName: "Mobilbahis",
    WebUrl: "mobilbahis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 273,
    isNew: false,
  },
  480: {
    idProvider: "480",
    logo: "480.png",
    WebName: "Truvabet",
    WebUrl: "truvabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 406,
    isNew: false,
  },
  481: {
    idProvider: "481",
    logo: "481.png",
    WebName: "BetCity.ru",
    WebUrl: "betcity-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 70,
    isNew: false,
  },
  482: {
    idProvider: "482",
    logo: "482.png",
    WebName: "BetCity",
    WebUrl: "betcity",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 69,
    isNew: false,
    bonus: {
      id: "300",
      title: "Free Bet up to €10!",
      text: "Register and get freebet $10!",
    },
  },
  483: {
    idProvider: "483",
    logo: "483.png",
    WebName: "Inkabet",
    WebUrl: "inkabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 232,
    isNew: false,
  },
  484: {
    idProvider: "484",
    logo: "484.png",
    WebName: "ParionsSport",
    WebUrl: "parionssport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 307,
    isNew: false,
  },
  485: {
    idProvider: "485",
    logo: "485.png",
    WebName: "Smarkets",
    WebUrl: "smarkets",
    IsBookmaker: "n",
    IsBettingExchange: "y",
    Url: "https://wlsmarkets.adsrv.eacdn.com/C.ashx?btag=a_1359b_37c_&affid=25908&siteid=1359&adid=37&c=",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 337,
    isNew: false,
    bonus: {
      id: "303",
      title: "0% Commission for 60 days or £20 Cash Refund!",
      text: 'Enter code "COMMFREE" to trade with 0% commission on all sports for 60days or enter code "SMK20" to get a £20 cash refund on losses.',
    },
  },
  486: {
    idProvider: "486",
    logo: "486.png",
    WebName: "OLIMPBET",
    WebUrl: "olimpbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 297,
    isNew: false,
  },
  487: {
    idProvider: "487",
    logo: "487.png",
    WebName: "Betpas",
    WebUrl: "betpas",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 110,
    isNew: false,
  },
  488: {
    idProvider: "488",
    logo: "488.png",
    WebName: "Bahis Arena",
    WebUrl: "bahis-arena",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 24,
    isNew: false,
  },
  489: {
    idProvider: "489",
    logo: "489.png",
    WebName: "Starvegas.it",
    WebUrl: "starvegas-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 363,
    isNew: false,
  },
  490: {
    idProvider: "490",
    // logo: "490.png",
    WebName: "Bwin.dk",
    WebUrl: "bwin-dk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 151,
    isNew: false,
  },
  491: {
    idProvider: "491",
    logo: "491.png",
    WebName: "Parimatch",
    WebUrl: "parimatch",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 304,
    isNew: false,
  },
  492: {
    idProvider: "492",
    logo: "492.png",
    WebName: "NightRush",
    WebUrl: "nightrush",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 287,
    isNew: false,
  },
  493: {
    idProvider: "493",
    logo: "493.png",
    WebName: "europebet",
    WebUrl: "europebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 189,
    isNew: false,
  },
  494: {
    idProvider: "494",
    logo: "494.png",
    WebName: "efbet",
    WebUrl: "efbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 181,
    isNew: false,
  },
  495: {
    idProvider: "495",
    logo: "495.png",
    WebName: "CasinoEstoril",
    WebUrl: "casinoestoril",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 160,
    isNew: false,
  },
  496: {
    idProvider: "496",
    WebName: "MarathonbetTest",
    WebUrl: "marathonbettest",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 264,
    isNew: false,
  },
  497: {
    idProvider: "497",
    logo: "497.png",
    WebName: "Ligastavok",
    WebUrl: "ligastavok",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 257,
    isNew: false,
  },
  498: {
    idProvider: "498",
    logo: "498.png",
    WebName: "TippmixPro",
    WebUrl: "tippmixpro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 391,
    isNew: false,
  },
  499: {
    idProvider: "499",
    WebName: "Betstars",
    WebUrl: "betstars",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 130,
    isNew: false,
  },
  500: {
    idProvider: "500",
    logo: "500.png",
    WebName: "22Bet",
    WebUrl: "22bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 12,
    isNew: false,
    bonus: {
      id: "306",
      title: "100% Bonus up to €122",
      text: "Sign up with 22bet and receive 100% bonus up to €122 on your first deposit.",
    },
  },
  501: {
    idProvider: "501",
    logo: "501.png",
    WebName: "SportPesa.it",
    WebUrl: "sportpesa-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 351,
    isNew: false,
  },
  502: {
    idProvider: "502",
    logo: "502.png",
    WebName: "LV BET",
    WebUrl: "lv-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 261,
    isNew: false,
  },
  503: {
    idProvider: "503",
    logo: "503.png",
    WebName: "Nairabet",
    WebUrl: "nairabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 283,
    isNew: false,
  },
  504: {
    idProvider: "504",
    logo: "504.png",
    WebName: "Sportimba",
    WebUrl: "sportimba",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 344,
    isNew: false,
  },
  505: {
    idProvider: "505",
    logo: "505.png",
    WebName: "Marsbahis",
    WebUrl: "marsbahis",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 266,
    isNew: false,
  },
  506: {
    idProvider: "506",
    logo: "506.png",
    WebName: "Betfarm",
    WebUrl: "betfarm",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 88,
    isNew: false,
  },
  507: {
    idProvider: "507",
    logo: "507.png",
    WebName: "Betsafe.lv",
    WebUrl: "betsafe-lv",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 122,
    isNew: false,
  },
  508: {
    idProvider: "508",
    logo: "508.png",
    WebName: "Betsafe.ee",
    WebUrl: "betsafe-ee",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 120,
    isNew: false,
  },
  509: {
    idProvider: "509",
    logo: "509.png",
    WebName: "Bet9ja",
    WebUrl: "bet9ja",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 45,
    isNew: false,
  },
  510: {
    idProvider: "510",
    logo: "510.png",
    WebName: "betin.ug",
    WebUrl: "betin-ug",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 97,
    isNew: false,
  },
  511: {
    idProvider: "511",
    logo: "511.png",
    WebName: "betin.ke",
    WebUrl: "betin-ke",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 96,
    isNew: false,
  },
  512: {
    idProvider: "512",
    logo: "512.png",
    WebName: "Betsafe.lt",
    WebUrl: "betsafe-lt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 121,
    isNew: false,
  },
  513: {
    idProvider: "513",
    logo: "513.png",
    WebName: "Supabets",
    WebUrl: "supabets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 371,
    isNew: false,
  },
  514: {
    idProvider: "514",
    logo: "514.png",
    WebName: "BetJoe",
    WebUrl: "betjoe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "y",
    sortKey: 100,
    isNew: false,
    bonus: {
      id: "308",
      title: "100% Sign Up Bonus!",
      text: "Register a new account and receive 100% first deposit bonus up to $5000!",
    },
  },
  515: {
    idProvider: "515",
    logo: "515.png",
    WebName: "Kwikbet.co.ke",
    WebUrl: "kwikbet-co-ke",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 248,
    isNew: false,
  },
  516: {
    idProvider: "516",
    logo: "516.png",
    WebName: "Betano.ro",
    WebUrl: "betano-ro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 62,
    isNew: false,
  },
  517: {
    idProvider: "517",
    logo: "517.png",
    WebName: "Profitbet",
    WebUrl: "profitbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 319,
    isNew: false,
  },
  518: {
    idProvider: "518",
    logo: "518.png",
    WebName: "SupabetsZA",
    WebUrl: "supabetsza",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 372,
    isNew: false,
  },
  519: {
    idProvider: "519",
    logo: "519.png",
    WebName: "GSB.com.ug",
    WebUrl: "gsb-com-ug",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 221,
    isNew: false,
  },
  520: {
    idProvider: "520",
    logo: "520.png",
    WebName: "KTO",
    WebUrl: "kto",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "http://bit.ly/2RmQNVg",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 247,
    isNew: false,
    bonus: {
      id: "309",
      title: "100% Bonus on First Deposit!",
      text: "Your account will be credited with a 100% (up to €100) match Bonus upon youfirst deposit!",
    },
  },
  521: {
    idProvider: "521",
    WebName: "Sportingbet.co.za",
    WebUrl: "sportingbet-co-za",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 347,
    isNew: false,
  },
  522: {
    idProvider: "522",
    logo: "522.png",
    WebName: "WSB.co.za",
    WebUrl: "wsb-co-za",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 448,
    isNew: false,
  },
  523: {
    idProvider: "523",
    logo: "523.png",
    WebName: "Wplay.co",
    WebUrl: "wplay-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 447,
    isNew: false,
  },
  524: {
    idProvider: "524",
    logo: "524.png",
    WebName: "Zamba.co",
    WebUrl: "zamba-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 453,
    isNew: false,
  },
  525: {
    idProvider: "525",
    logo: "525.png",
    WebName: "Betrally India",
    WebUrl: "betrally-india",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 114,
    isNew: false,
  },
  526: {
    idProvider: "526",
    logo: "526.png",
    WebName: "Speedybet",
    WebUrl: "speedybet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 341,
    isNew: false,
  },
  527: {
    idProvider: "527",
    logo: "527.png",
    WebName: "Parimatch.ru",
    WebUrl: "parimatch-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 305,
    isNew: false,
  },
  528: {
    idProvider: "528",
    logo: "528.png",
    WebName: "Snabbare",
    WebUrl: "snabbare",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 338,
    isNew: false,
  },
  529: {
    idProvider: "529",
    logo: "529.png",
    WebName: "Hajper",
    WebUrl: "hajper",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 223,
    isNew: false,
  },
  530: {
    idProvider: "530",
    WebName: "Vivelasuerte",
    WebUrl: "vivelasuerte",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 430,
    isNew: false,
  },
  531: {
    idProvider: "531",
    logo: "531.png",
    WebName: "Marathonbet.ru",
    WebUrl: "marathonbet-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "158",
    sr: 1,
    IsPremium: "n",
    sortKey: 263,
    isNew: false,
    bonus: {
      id: "311",
      title: "Cashback for New Users!",
      text: "10% Cashback up to 5000 RUB paid in free bets for all new users!",
    },
  },
  532: {
    idProvider: "532",
    logo: "532.png",
    WebName: "DoradoBet",
    WebUrl: "doradobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 178,
    isNew: false,
  },
  533: {
    idProvider: "533",
    logo: "533.png",
    WebName: "Betway Africa",
    WebUrl: "betway-africa",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 136,
    isNew: false,
  },
  534: {
    idProvider: "534",
    logo: "534.png",
    WebName: "SuperSport",
    WebUrl: "supersport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 377,
    isNew: false,
  },
  535: {
    idProvider: "535",
    logo: "535.png",
    WebName: "MrGreen",
    WebUrl: "mrgreen",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 277,
    isNew: false,
    bonus: {
      id: "312",
      title: "2x €10 Free Bets!",
      text: "Bet €20 or more in Mr Green’s Sportsbook, ensuring the total odds are 1.80 orhigher. Upon settlement receive 2x €10 Free Bets to use on any sport.",
    },
  },
  536: {
    idProvider: "536",
    logo: "536.png",
    WebName: "Betbiga",
    WebUrl: "betbiga",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 64,
    isNew: false,
  },
  537: {
    idProvider: "537",
    WebName: "1bet",
    WebUrl: "1bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 8,
    isNew: false,
  },
  538: {
    idProvider: "538",
    logo: "538.png",
    WebName: "Parimatchtz",
    WebUrl: "parimatchtz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 306,
    isNew: false,
  },
  539: {
    idProvider: "539",
    logo: "539.png",
    WebName: "Betclic.pl",
    WebUrl: "betclic-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 75,
    isNew: false,
  },
  540: {
    idProvider: "540",
    logo: "540.png",
    WebName: "10cric",
    WebUrl: "10cric",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 2,
    isNew: false,
  },
  541: {
    idProvider: "541",
    logo: "541.png",
    WebName: "Rushbet.co",
    WebUrl: "rushbet-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 326,
    isNew: false,
  },
  542: {
    idProvider: "542",
    WebName: "Fortebet",
    WebUrl: "fortebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 203,
    isNew: false,
  },
  543: {
    idProvider: "543",
    logo: "543.png",
    WebName: "Betago",
    WebUrl: "betago",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 47,
    isNew: false,
  },
  544: {
    idProvider: "544",
    logo: "544.png",
    WebName: "Betika",
    WebUrl: "betika",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 93,
    isNew: false,
  },
  545: {
    idProvider: "545",
    logo: "545.png",
    WebName: "Betano.pt",
    WebUrl: "betano-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 61,
    isNew: false,
  },
  546: {
    idProvider: "546",
    logo: "546.png",
    WebName: "Kirolbet",
    WebUrl: "kirolbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 245,
    isNew: false,
  },
  547: {
    idProvider: "547",
    logo: "547.png",
    WebName: "Fonbet.kz",
    WebUrl: "fonbet-kz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 202,
    isNew: false,
  },
  548: {
    idProvider: "548",
    logo: "548.png",
    WebName: "Domusbet",
    WebUrl: "domusbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 177,
    isNew: false,
  },
  549: {
    idProvider: "549",
    logo: "549.png",
    WebName: "bet365.nj",
    WebUrl: "bet365-nj",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 38,
    isNew: false,
  },
  550: {
    idProvider: "550",
    logo: "550.png",
    WebName: "GGBET",
    WebUrl: "ggbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 211,
    isNew: false,
    bonus: {
      id: "313",
      title: "Bet Without Risk up to €25!",
      text: 'Enter the "COMEBACK" promotional code and make the first deposit inamount €10 or more. Get bet insurance up to €25!',
    },
  },
  551: {
    idProvider: "551",
    logo: "551.png",
    WebName: "Luckia.pt",
    WebUrl: "luckia-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 259,
    isNew: false,
  },
  552: {
    idProvider: "552",
    logo: "552.png",
    WebName: "BetAlfa.co",
    WebUrl: "betalfa-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 49,
    isNew: false,
  },
  553: {
    idProvider: "553",
    logo: "553.png",
    WebName: "Circus.pe",
    WebUrl: "circus-pe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 166,
    isNew: false,
  },
  554: {
    idProvider: "554",
    logo: "554.png",
    WebName: "Betfan.pl_old",
    WebUrl: "betfan-pl_old",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 87,
    isNew: false,
  },
  555: {
    idProvider: "555",
    logo: "555.png",
    WebName: "Toto.nederlandse",
    WebUrl: "toto-nederlandse",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 400,
    isNew: false,
  },
  556: {
    idProvider: "556",
    logo: "556.png",
    WebName: "BetjuegoOld",
    WebUrl: "betjuegoold",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 101,
    isNew: false,
  },
  557: {
    idProvider: "557",
    logo: "557.png",
    WebName: "Totonederlandse",
    WebUrl: "totonederlandse",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 403,
    isNew: false,
  },
  558: {
    idProvider: "558",
    logo: "558.png",
    WebName: "GGBET.ru",
    WebUrl: "ggbet-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://ggbet.life/ggbetting/rus?lp=00&lg=ru¶m=livesport_oddsportal-rusb&only=rus&deeplink=sports",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "158",
    sr: 1,
    IsPremium: "n",
    sortKey: 212,
    isNew: false,
  },
  559: {
    idProvider: "559",
    logo: "559.png",
    WebName: "X-bet.co",
    WebUrl: "x-bet-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 450,
    isNew: false,
    bonus: {
      id: "314",
      title: "€100 Deposit Bonus!",
      text: "Make your first deposit entering bonus code and you will receive a 100% bonusup to 100 Euro or the amount in the equivalent currency. Bonus code: X100",
    },
  },
  560: {
    idProvider: "560",
    logo: "560.png",
    WebName: "Topaz.az",
    WebUrl: "topaz-az",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 398,
    isNew: false,
  },
  561: {
    idProvider: "561",
    logo: "561.png",
    WebName: "OLIMPBET.kz",
    WebUrl: "olimpbet-kz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 298,
    isNew: false,
  },
  562: {
    idProvider: "562",
    logo: "562.png",
    WebName: "Yajuego",
    WebUrl: "yajuego",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 451,
    isNew: false,
  },
  563: {
    idProvider: "563",
    WebName: "Bwin.ru",
    WebUrl: "bwin-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 156,
    isNew: false,
  },
  564: {
    idProvider: "564",
    WebName: "unibet.nj",
    WebUrl: "unibet-nj",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 412,
    isNew: false,
  },
  565: {
    idProvider: "565",
    logo: "565.png",
    WebName: "BetAndYou",
    WebUrl: "betandyou",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 50,
    isNew: false,
  },
  566: {
    idProvider: "566",
    logo: "566.png",
    WebName: "Placard",
    WebUrl: "placard",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 315,
    isNew: false,
  },
  567: {
    idProvider: "567",
    logo: "567.png",
    WebName: "BetBoom",
    WebUrl: "betboom",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 66,
    isNew: false,
  },
  568: {
    idProvider: "568",
    logo: "568.png",
    WebName: "Bilyoner",
    WebUrl: "bilyoner",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 141,
    isNew: false,
  },
  569: {
    idProvider: "569",
    logo: "569.png",
    WebName: "PZBuk",
    WebUrl: "pzbuk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 320,
    isNew: false,
  },
  570: {
    idProvider: "570",
    logo: "570.png",
    WebName: "Sts.bet.com",
    WebUrl: "sts-bet-com",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 367,
    isNew: false,
  },
  571: {
    idProvider: "571",
    logo: "571.png",
    WebName: "Sts.co.uk",
    WebUrl: "sts-co-uk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 368,
    isNew: false,
  },
  572: {
    idProvider: "572",
    logo: "572.png",
    WebName: "BETFAN",
    WebUrl: "betfan",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 86,
    isNew: false,
  },
  573: {
    idProvider: "573",
    logo: "573.png",
    WebName: "MELbet",
    WebUrl: "melbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 270,
    isNew: false,
    bonus: {
      id: "315",
      title: "100% up to €100 and an Additional 30%!",
      text: '100% bonus up to €100 and an additional 30% with the promo code:"odds30"',
    },
  },
  574: {
    idProvider: "574",
    logo: "574.png",
    WebName: "Betano.br",
    WebUrl: "betano-br",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 52,
    isNew: false,
  },
  575: {
    idProvider: "575",
    logo: "575.png",
    WebName: "BetInAsia",
    WebUrl: "betinasia",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1669800660",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 98,
    isNew: false,
    bonus: {
      id: "317",
      title: "Get 25% First Deposit Bonus!",
      text: 'Get 25% first deposit bonus for your new BLACK account by using the promocode "25BLACK".',
    },
  },
  576: {
    idProvider: "576",
    logo: "576.png",
    WebName: "Fastbet",
    WebUrl: "fastbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 197,
    isNew: false,
  },
  577: {
    idProvider: "577",
    logo: "577.png",
    WebName: "Betsensation",
    WebUrl: "betsensation",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 123,
    isNew: false,
    bonus: {
      id: "318",
      title: "100% Welcome Free Bet up to €100/$100!",
      text: "Sign up and make first bet. And get the bonus of 100% that matches your firstbet amount for free.",
    },
  },
  578: {
    idProvider: "578",
    logo: "578.png",
    WebName: "Betmaster",
    WebUrl: "betmaster",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://record.affiliatesbm2.com/_lHFPxgS9m2rygWvE8wtUVWNd7ZgqdRLk/6/",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 102,
    isNew: false,
    bonus: {
      id: "320",
      title: "Get 100% up to €200 on Your First Deposit!",
      text: "elect 100% first deposit bonus on Sports to receive the 100% on your firstdeposit up to €150 to use on Sports only + €5 Freebet every week for 4 weeks.",
    },
  },
  579: {
    idProvider: "579",
    logo: "579.png",
    WebName: "BetPlanet",
    WebUrl: "betplanet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 112,
    isNew: false,
  },
  581: {
    idProvider: "581",
    logo: "581.png",
    WebName: "10x10bet",
    WebUrl: "10x10bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.tentenb.com/affiliates/?btag=464923_l129611",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 3,
    isNew: false,
  },
  582: {
    idProvider: "582",
    logo: "582.png",
    WebName: "Soccabet",
    WebUrl: "soccabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 339,
    isNew: false,
  },
  583: {
    idProvider: "583",
    logo: "583.png",
    WebName: "Curebet",
    WebUrl: "curebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 171,
    isNew: false,
  },
  584: {
    idProvider: "584",
    logo: "584.png",
    WebName: "Lasbet",
    WebUrl: "lasbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1620395783",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 250,
    isNew: false,
  },
  585: {
    idProvider: "585",
    logo: "585.png",
    WebName: "Betika.com.gh",
    WebUrl: "betika-com-gh",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 95,
    isNew: false,
  },
  586: {
    idProvider: "586",
    logo: "586.png",
    WebName: "Circus",
    WebUrl: "circus",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 165,
    isNew: false,
  },
  587: {
    idProvider: "587",
    logo: "587.png",
    WebName: "Betway PT",
    WebUrl: "betway-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 138,
    isNew: false,
  },
  588: {
    idProvider: "588",
    logo: "588.png",
    WebName: "FEZbet",
    WebUrl: "fezbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://awbba.zetcasino.com/redirect.aspx?pid=1351931&bid=1658",
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 199,
    isNew: false,
    bonus: {
      id: "321",
      title: "€100 First Deposit Bonus!",
      text: "Register now and enjoy 100% first deposit bonus up to €100!",
    },
  },
  589: {
    idProvider: "589",
    logo: "589.png",
    WebName: "Betway Africa Mozambique",
    WebUrl: "betway-africa-mozambique",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 137,
    isNew: false,
  },
  590: {
    idProvider: "590",
    logo: "590.png",
    WebName: "Rojabet",
    WebUrl: "rojabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 325,
    isNew: false,
  },
  591: {
    idProvider: "591",
    logo: "591.png",
    WebName: "Superbet.pl",
    WebUrl: "superbet-pl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 375,
    isNew: false,
  },
  592: {
    idProvider: "592",
    logo: "592.png",
    WebName: "Superbet.ro",
    WebUrl: "superbet-ro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 376,
    isNew: false,
  },
  593: {
    idProvider: "593",
    logo: "593.png",
    WebName: "Tennisi.kz",
    WebUrl: "tennisi-kz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 382,
    isNew: false,
  },
  594: {
    idProvider: "594",
    logo: "594.png",
    WebName: "BetFinal",
    WebUrl: "betfinal",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 89,
    isNew: false,
    bonus: {
      id: "322",
      title: "Welcome Bonus Up to $100!",
      text: "Join Betfinal and get your 100% up to $100 Sports Welcome Bonus!",
    },
  },
  595: {
    idProvider: "595",
    logo: "595.png",
    WebName: "Solverde.pt",
    WebUrl: "solverde-pt",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 340,
    isNew: false,
  },
  596: {
    idProvider: "596",
    logo: "596.png",
    WebName: "bet365.ru",
    WebUrl: "bet365-ru",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 40,
    isNew: false,
  },
  597: {
    idProvider: "597",
    logo: "597.png",
    WebName: "Latribet",
    WebUrl: "latribet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 251,
    isNew: false,
  },
  598: {
    idProvider: "598",
    logo: "598.png",
    WebName: "Bwin.co",
    WebUrl: "bwin-co",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 150,
    isNew: false,
  },
  599: {
    idProvider: "599",
    logo: "599.png",
    WebName: "MSport",
    WebUrl: "msport",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 279,
    isNew: false,
  },
  600: {
    idProvider: "600",
    logo: "600.png",
    WebName: "Chezacash",
    WebUrl: "chezacash",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 163,
    isNew: false,
  },
  601: {
    idProvider: "601",
    logo: "601.png",
    WebName: "GreatOdds",
    WebUrl: "greatodds",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 219,
    isNew: false,
  },
  602: {
    idProvider: "602",
    WebName: "GreatOdds.rs",
    WebUrl: "greatodds-rs",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 220,
    isNew: false,
  },
  603: {
    idProvider: "603",
    logo: "603.png",
    WebName: "CasaPariurilor",
    WebUrl: "casapariurilor",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 159,
    isNew: false,
  },
  604: {
    idProvider: "604",
    logo: "604.png",
    WebName: "Fonbet.by",
    WebUrl: "fonbet-by",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 200,
    isNew: false,
  },
  605: {
    idProvider: "605",
    logo: "605.png",
    WebName: "bet365.nl",
    WebUrl: "bet365-nl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 39,
    isNew: false,
  },
  608: {
    idProvider: "608",
    logo: "608.png",
    WebName: "efbet.it",
    WebUrl: "efbet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 183,
    isNew: false,
  },
  609: {
    idProvider: "609",
    logo: "609.png",
    WebName: "N1 Bet",
    WebUrl: "n1-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 282,
    isNew: false,
    bonus: {
      id: "325",
      title: "10% No Risk Free Bet!",
      text: "Enjoy your wlecome bonus - 10% no risk free bet up to €200!",
    },
  },
  610: {
    idProvider: "610",
    logo: "610.png",
    WebName: "Favbet",
    WebUrl: "favbet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 198,
    isNew: false,
  },
  611: {
    idProvider: "611",
    logo: "611.png",
    WebName: "Golpas",
    WebUrl: "golpas",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 217,
    isNew: false,
  },
  612: {
    idProvider: "612",
    logo: "612.png",
    WebName: "Ditobet",
    WebUrl: "ditobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "y",
    sortKey: 176,
    isNew: false,
    bonus: {
      id: "324",
      title: "100% up to €100!",
      text: "The 100% Welcome Sports Bonus up to €100 is available only for the first-everdeposit at Ditobet!",
    },
  },
  613: {
    idProvider: "613",
    logo: "613.png",
    WebName: "Efbet RO",
    WebUrl: "efbet-ro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 182,
    isNew: false,
  },
  614: {
    idProvider: "614",
    logo: "614.png",
    WebName: "Betring",
    WebUrl: "betring",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 116,
    isNew: false,
  },
  615: {
    idProvider: "615",
    WebName: "ErisBet365",
    WebUrl: "erisbet365",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 185,
    isNew: false,
  },
  616: {
    idProvider: "616",
    logo: "616.png",
    WebName: "OlyBet.it",
    WebUrl: "olybet-it",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 299,
    isNew: false,
  },
  617: {
    idProvider: "617",
    logo: "617.png",
    WebName: "VOBET",
    WebUrl: "vobet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: "https://www.bigvobet1.com/affiliates/?btag=178779_l151318",
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1654519626",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 431,
    isNew: false,
  },
  618: {
    idProvider: "618",
    logo: "618.png",
    WebName: "Betera",
    WebUrl: "betera",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 83,
    isNew: false,
  },
  619: {
    idProvider: "619",
    logo: "619.png",
    WebName: "Unibetbe",
    WebUrl: "unibetbe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 414,
    isNew: false,
  },
  620: {
    idProvider: "620",
    logo: "620.png",
    WebName: "Unibetdk",
    WebUrl: "unibetdk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 416,
    isNew: false,
  },
  621: {
    idProvider: "621",
    logo: "621.png",
    WebName: "Unibetnl",
    WebUrl: "unibetnl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 419,
    isNew: false,
  },
  622: {
    idProvider: "622",
    logo: "622.png",
    WebName: "Unibetca",
    WebUrl: "unibetca",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 415,
    isNew: false,
  },
  623: {
    idProvider: "623",
    logo: "623.png",
    WebName: "Unibetro",
    WebUrl: "unibetro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 420,
    isNew: false,
  },
  624: {
    idProvider: "624",
    logo: "624.png",
    WebName: "Unibetse",
    WebUrl: "unibetse",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 421,
    isNew: false,
  },
  625: {
    idProvider: "625",
    logo: "625.png",
    WebName: "Unibetuk",
    WebUrl: "unibetuk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 422,
    isNew: false,
  },
  626: {
    idProvider: "626",
    logo: "626.png",
    WebName: "Unibethu",
    WebUrl: "unibethu",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 418,
    isNew: false,
  },
  627: {
    idProvider: "627",
    logo: "627.png",
    WebName: "Unibetee",
    WebUrl: "unibetee",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 417,
    isNew: false,
  },
  628: {
    idProvider: "628",
    logo: "628.png",
    WebName: "Unibetau",
    WebUrl: "unibetau",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 413,
    isNew: false,
  },
  629: {
    idProvider: "629",
    WebName: "ErisBetterIt",
    WebUrl: "erisbetterit",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 186,
    isNew: false,
  },
  630: {
    idProvider: "630",
    logo: "630.png",
    WebName: "Winnermx",
    WebUrl: "winnermx",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 444,
    isNew: false,
  },
  631: {
    idProvider: "631",
    logo: "630.png",
    WebName: "Calientemx",
    WebUrl: "calientemx",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 157,
    isNew: false,
  },
  632: {
    idProvider: "632",
    logo: "632.png",
    WebName: "Novibet",
    WebUrl: "novibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 291,
    isNew: false,
  },
  634: {
    idProvider: "634",
    logo: "634.png",
    WebName: "Betssonco",
    WebUrl: "betssonco",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 127,
    isNew: false,
  },
  635: {
    idProvider: "635",
    logo: "635.png",
    WebName: "BC.Game",
    WebUrl: "bc-game",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 25,
    isNew: false,
  },
  636: {
    idProvider: "636",
    logo: "636.png",
    WebName: "Starcasinoit",
    WebUrl: "starcasinoit",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 361,
    isNew: false,
  },
  637: {
    idProvider: "637",
    WebName: "Betssonno",
    WebUrl: "betssonno",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 129,
    isNew: false,
  },
  638: {
    idProvider: "638",
    logo: "638.png",
    WebName: "Alphabet",
    WebUrl: "alphabet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1670761888",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 20,
    isNew: false,
  },
  639: {
    idProvider: "639",
    WebName: "Bwin Africa",
    WebUrl: "bwin-africa",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 149,
    isNew: false,
  },
  640: {
    idProvider: "640",
    logo: "640.png",
    WebName: "Pinup",
    WebUrl: "pinup",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 314,
    isNew: false,
  },
  641: {
    idProvider: "641",
    logo: "641.png",
    WebName: "Suprabets",
    WebUrl: "suprabets",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "y",
    NewTo: "1676455311",
    SetNew: "1",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "y",
    sortKey: 378,
    isNew: false,
    bonus: {
      id: "327",
      title: "Risk Free Bet 50!",
      text: "Deposit and place your first bet. If you lose, you will be awarded a full refundbonus up to 50€!",
    },
  },
  642: {
    idProvider: "642",
    logo: "642.png",
    WebName: "Golden Palace",
    WebUrl: "golden-palace",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 216,
    isNew: false,
  },
  643: {
    idProvider: "643",
    logo: "643.png",
    WebName: "Fonbet.ca",
    WebUrl: "fonbet-ca",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 201,
    isNew: false,
  },
  644: {
    idProvider: "644",
    logo: "644.png",
    WebName: "Vincitu",
    WebUrl: "vincitu",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 427,
    isNew: false,
  },
  645: {
    idProvider: "645",
    logo: "645.png",
    WebName: "bet-at-home.de",
    WebUrl: "bet-at-home-de",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 31,
    isNew: false,
  },
  646: {
    idProvider: "646",
    logo: "646.png",
    WebName: "Mozzartbetke",
    WebUrl: "mozzartbetke",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 274,
    isNew: false,
  },
  647: {
    idProvider: "647",
    logo: "647.png",
    WebName: "Mozzartbetrs",
    WebUrl: "mozzartbetrs",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 276,
    isNew: false,
  },
  648: {
    idProvider: "648",
    logo: "648.png",
    WebName: "Mozzartbetro",
    WebUrl: "mozzartbetro",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 275,
    isNew: false,
  },
  649: {
    idProvider: "649",
    logo: "649.png",
    WebName: "Betssonmx",
    WebUrl: "betssonmx",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 128,
    isNew: false,
  },
  650: {
    idProvider: "650",
    logo: "650.png",
    WebName: "Aposta Ganha",
    WebUrl: "aposta-ganha",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 21,
    isNew: false,
  },
  651: {
    idProvider: "651",
    WebName: "sx.bet",
    WebUrl: "sx-bet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 379,
    isNew: false,
  },
  652: {
    idProvider: "652",
    logo: "652.png",
    WebName: "Expektse",
    WebUrl: "expektse",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 195,
    isNew: false,
  },
  653: {
    idProvider: "653",
    logo: "653.png",
    WebName: "Expektdk",
    WebUrl: "expektdk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 194,
    isNew: false,
  },
  654: {
    idProvider: "654",
    logo: "654.png",
    WebName: "Spinbookie",
    WebUrl: "spinbookie",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 342,
    isNew: false,
  },
  655: {
    idProvider: "655",
    logo: "655.png",
    WebName: "Betclic.ci",
    WebUrl: "betclic-ci",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 72,
    isNew: false,
  },
  656: {
    idProvider: "656",
    logo: "656.png",
    WebName: "Stoiximan.cy",
    WebUrl: "stoiximan-cy",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 364,
    isNew: false,
  },
  657: {
    idProvider: "657",
    logo: "657.png",
    WebName: "Betano.cz",
    WebUrl: "betano-cz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 56,
    isNew: false,
  },
  658: {
    idProvider: "658",
    logo: "658.png",
    WebName: "Betano.de",
    WebUrl: "betano-de",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 57,
    isNew: false,
  },
  659: {
    idProvider: "659",
    logo: "659.png",
    WebName: "Betano.cl",
    WebUrl: "betano-cl",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 55,
    isNew: false,
  },
  660: {
    idProvider: "660",
    logo: "660.png",
    WebName: "Betano.pe",
    WebUrl: "betano-pe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 60,
    isNew: false,
  },
  661: {
    idProvider: "661",
    logo: "661.png",
    WebName: "Betano.ec",
    WebUrl: "betano-ec",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 58,
    isNew: false,
  },
  662: {
    idProvider: "662",
    logo: "662.png",
    WebName: "Betano.ng",
    WebUrl: "betano-ng",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 59,
    isNew: false,
  },
  663: {
    idProvider: "663",
    logo: "663.png",
    WebName: "Betano.bg",
    WebUrl: "betano-bg",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 51,
    isNew: false,
  },
  664: {
    idProvider: "664",
    logo: "664.png",
    WebName: "Ibet",
    WebUrl: "ibet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 228,
    isNew: false,
  },
  665: {
    idProvider: "665",
    logo: "665.png",
    WebName: "Betano.ca (Ontario)",
    WebUrl: "betano-ca-ontario",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 53,
    isNew: false,
  },
  666: {
    idProvider: "666",
    logo: "666.png",
    WebName: "Betano.ca (rest of canada)",
    WebUrl: "betano-ca-rest-of-canada",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 54,
    isNew: false,
  },
  667: {
    idProvider: "667",
    logo: "667.png",
    WebName: "Zebet",
    WebUrl: "zebet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 454,
    isNew: false,
  },
  668: {
    idProvider: "668",
    logo: "668.png",
    WebName: "VbetFR",
    WebUrl: "vbetfr",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 423,
    isNew: false,
  },
  679: {
    idProvider: "679",
    WebName: "Betika Zambia",
    WebUrl: "betika-zambia",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 94,
    isNew: false,
  },
  699: {
    idProvider: "699",
    WebName: "LeoVegasPE",
    WebUrl: "leovegaspe",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 256,
    isNew: false,
  },
  707: {
    idProvider: "707",
    logo: "707.png",
    WebName: "BetMGM",
    WebUrl: "betmgm",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 103,
    isNew: false,
  },
  709: {
    idProvider: "709",
    WebName: "VbetUK",
    WebUrl: "vbetuk",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 424,
    isNew: false,
  },
  711: {
    idProvider: "711",
    logo: "711.png",
    WebName: "Germania",
    WebUrl: "germania",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 209,
    isNew: false,
  },
  713: {
    idProvider: "713",
    logo: "713.png",
    WebName: "CoolbetLATAM",
    WebUrl: "coolbetlatam",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 169,
    isNew: false,
  },
  715: {
    idProvider: "715",
    // logo: "715.png",
    WebName: "PerformanceTest",
    WebUrl: "performancetest",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 311,
    isNew: false,
  },
  723: {
    idProvider: "723",
    logo: "723.png",
    WebName: "Winlinekz",
    WebUrl: "winlinekz",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 440,
    isNew: false,
  },
  727: {
    idProvider: "727",
    logo: "727.png",
    WebName: "Winlineby",
    WebUrl: "winlineby",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 439,
    isNew: false,
  },
  737: {
    idProvider: "737",
    WebName: "Ubet",
    WebUrl: "ubet",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 1,
    IsPremium: "n",
    sortKey: 407,
    isNew: false,
  },
  739: {
    idProvider: "739",
    WebName: "PerformanceTestGo",
    WebUrl: "performancetestgo",
    IsBookmaker: "y",
    IsBettingExchange: "n",
    Url: null,
    DefaultBetslipUrl: null,
    Active: "n",
    NewTo: "0",
    SetNew: "0",
    PreferredCountryID: "0",
    sr: 0,
    IsPremium: "n",
    sortKey: 312,
    isNew: false,
  },
};

export default BOOKIES_DATA;
