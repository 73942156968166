import React, { useEffect, useState, useRef } from "react";
import PagesNav from "../DroppingOdds/PagesNav";
import AboutDroppingOdds from "../DroppingOdds/AboutDroppingOdds";
import droppingStyle from "../../Pages/DroppingOdds/DroppingOdds.module.css";
import matchWithoddStyle from "../../Pages/MatchWithOdds/MatchWithOdds.module.css";
import styles from "../../Pages/BookMakers/bookmakers.module.css";
import { Stack, Table, Tooltip } from "react-bootstrap";
import MycouponMsgBtn from "../MyCoupon/MycouponMsgBtn";
import { Icon } from "@iconify/react/dist/iconify.js";
import BOOKIES_DATA from "../../data/bookies";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import PostRequest from "../../services/PostRequest";
import axios from "axios";

const BookmakersComponent = () => {
  const [loading, setLoading] = useState(true);
  const [bookies, setBookies] = useState([]);
  const defaultFetchTriggered = useRef(false);

  const getBookies = async (country = "DEFAULT") => {
    setLoading(true);
    try {
      const response = await PostRequest("/bookmakers", { country: country });
      if (response) {
        setBookies(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGeoInfo = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      if (response) {
        getBookies(response.data.country_name);
      }
    } catch (error) {
      console.log(error);
      getBookies("DEFAULT");
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    if (!loading && bookies.length === 0 && !defaultFetchTriggered.current) {
      defaultFetchTriggered.current = true; // Mark default fetch as triggered
      getBookies("DEFAULT");
    }
  }, [bookies, loading]);

  return (
    <div className={`${droppingStyle.droppingOddsBg} mt-3`}>
      <PagesNav nextTab="Bookmakers" />
      <AboutDroppingOdds
        disciption={true}
        title="Bookmakers Comparison - Where to Bet?"
      />

      {loading ? (
        <p>Loading...</p>
      ) : (
        bookies.length > 0 ? (
          bookies.map((booky, index) => {
            if (booky.fullDescriptions) {
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: `<p>${booky.fullDescriptions}</p>`,
                  }}
                ></div>
              );
            }
            return null;
          })
        ) : (
          <p>No bookmakers available.</p>
        )
      )}
    </div>
  );
};

export default BookmakersComponent;
